import { create } from "zustand";

export const userStore = create((set, get) => ({
  userData: null,
  loading: false,
  btoLoading: false,
  btoData: {},
  btoModelData: {},
  expirationDatesOfTicker: {},
  socket: null,
  wishlistData: null,
  uniqueString: "start",
  pnlData: {},
  selectedDiscordChannel: null,
  // selectedOptionArray: ['SPY', 'AAPL', 'AMD', 'QQQ', 'META'],

  modelData: [],

  //configs
  savedConfig: {},
  savedDataConfig: {},

  //brokerList
  brokerList: [],

  //Model Data Auth
  hasDataBrokerTokens: null,
  dataAuthBroker: null,
  dataAuthBrokerId: null,
  // dataAccountList: null,
  // dataSelectedAccount: null,
  // brokerBalance: null,
  // dataBrokerInfo: null,

  
  //Order Placement Auth
  hasBrokerTokens: null,
  authBroker: null,
  authBrokerId: null,
  accountList: null,
  selectedAccount: null,
  brokerBalance: null,
  brokerInfo: null,

  isPaperTrade: JSON.parse(localStorage.getItem("paperTrade")),

  //Loader for trade type change and balance
  tradeTypeLoader: false,
  balanceLoader: false,

  //Stc Real Trade Options
  orderTypeStc: "limit",
  limitPriceStc: null,
  stopPriceStc: null,
  timeInForceStc: "day",

  tradeWarning: false,

  setUserData: (values) => {
    set({
      userData: { ...values },
    });
  },
  setLoading: (value) => set({ loading: value }),
  setBtoData: (values) =>
    set({
      btoData: { ...get().btoData, ...values },
    }),
  setBtoModelData: (values) =>
    set({
      btoModelData: { ...get().btoModelData, ...values },
    }),
  setExpirationDatesOfTicker: (values) =>
    set({
      expirationDatesOfTicker: { ...get().expirationDatesOfTicker, ...values },
    }),
  setBtoLoading: (value) => set({ btoLoading: value }),
  setSocket: (value) => set({ socket: value }),
  setWishListData: (value) => set({ wishlistData: value }),
  setUniqueString: (value) => set({ uniqueString: value }),
  setPnlData: (value) => set({ pnlData: value}),
  setSelectedDiscordChannel: (channel) => set({ selectedDiscordChannel: channel}),
  // setSelectedOptionArray: (newArray) => set({ selectedOptionArray: newArray }),
  
  setModelData: (newArray) => set({ modelData: newArray }),

  //Configs
  setSavedConfig: (value) => set({ savedConfig: value }),
  setSavedDataConfig: (value) => set({ savedDataConfig: value}),

  //Broker List
  setBrokerList: (value) => set({ brokerList: value}),
  
  //Model Data Auth
  setHasDataBrokerTokens: (value) => set({ hasDataBrokerTokens: value }),
  setDataAuthBroker: (value) => set({ dataAuthBroker: value }),
  setDataAuthBrokerId: (value) => set({ dataAuthBrokerId: value}),
  
  //Order Placement Auth
  setHasBrokerTokens: (value) => set({ hasBrokerTokens: value }),
  setAuthBroker: (value) => set({ authBroker: value }),
  setAuthBrokerId: (value) => set({ authBrokerId: value}),
  setAccountList: (newArray) => set({accountList: newArray}),
  setSelectedAccount: (value) => set({selectedAccount: value}),
  setBrokerBalance: (value) => set({brokerBalance: value}),

  setIsPaperTrade: (value) => set({ isPaperTrade: value }),
  setIsBrokerInfo: (value) => set({ brokerInfo: value }),

  setTradeTypeLoader: (value) => set({ tradeTypeLoader: value }),
  setBalanceLoader: (value) => set({ balanceLoader: value }),

  //Stc real trade options
  setOrderTypeStc: (value) => set({ orderTypeStc: value}),
  setLimitPriceStc: (newArray) => set({limitPriceStc: newArray}),
  setStopPriceStc: (value) => set({stopPriceStc: value}),
  setTimeInForceStc: (value) => set({timeInForceStc: value}),
  shiftBto: (item, fromKey, toKey) =>
    set((state) => {
      // Find the index of the item in the source array
      const updatedFromArray = state.btoModelData[fromKey].filter((i) => i.id !== item.id);

      // Add the item to the target array
      const updatedToArray = [...state.btoModelData[toKey], item];

      // Update the state immutably
      return {
        btoModelData: {
          ...state.btoModelData,
          [fromKey]: updatedFromArray,
          [toKey]: updatedToArray,
        },
      };
    }),
  
  setTradeWarning: (value) => set({tradeWarning: value}),
}));
