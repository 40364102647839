import React from "react";
import "./loader.css"

const LoaderSecondary = () => {
  return (
    <div class="loader-secondary">
    </div>
  );
};

export default LoaderSecondary;
