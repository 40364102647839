import React, { useEffect, useState } from "react";
import { userStore } from "../../../store/user";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Sidebar from "../../../components/Sidebar/Sidebar";

import bgLeft from "../../../assets/bgLeftRectangle.png";
import profile from "../../../assets/defaultPicture.png";
import tradier from "../../../assets/icons/dashboard/tradier.png";
import eTrade from "../../../assets/icons/dashboard/etrade.png";
import info from "../../../assets/icons/info.png";
import close from "../../../assets/icons/cross.png";

import getToken from "../../../assets/Images/Dashboard/getToken.png";
import auth from "../../../assets/Images/Dashboard/auth.png";
import permission from "../../../assets/Images/Dashboard/permission.png";
import code from "../../../assets/Images/Dashboard/code.png";
import enterToken from "../../../assets/Images/Dashboard/enterToken.png";

import Accordion from "../../../components/Accordion/Accordion";
import { toast } from "react-toastify";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import DashHeader from "../../../components/DashHeader/DashHeader";
import Loader from "../../../components/Loader";
import Skeleton from "react-loading-skeleton";
import ModelAuth from "../../../components/dashboard/auth/ModelAuth";
import OrderAuth from "../../../components/dashboard/auth/OrderAuth";

const DashboardHome = () => {
  let state = "";
  const navigate = useNavigate()
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [btoStoreFilteredData, setBtoStoreFilteredData] = useState();
  const [recentTrades, setRecentTrades] = useState();
  const [btoStoreData, setBtoStoreData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  // const [accountList, setAccountList] = useState();
  // const [selectedAccount, setSelectedAccount] = useState();

  const [newsData, setNewsData] = useState()

  const [selectedService, setSelectedService] = useState("eTrade")
  const [eTradeDialog, setETradeDialog] = useState(false)
  const [eTradeLoading, setETradeLoading] = useState(false) 
  const [eTradeAccessToken, setETradeAccessToken] = useState("")

  const [instruction, setInstruction] = useState(false);
  
  const {
    userData,
    btoData,
    hasBrokerTokens,
    setHasBrokerTokens,
    hasDataBrokerTokens,
    authBroker,
    setAuthBroker,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    setBrokerBalance,
    setIsPaperTrade,
    brokerBalance,
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance
  } = userData || {};

  const location = useLocation();

  // TODO: Needs to come from server - oauth has implementation
  const scopes = "read, write, trade, market, stream";


  // const handleTradierAuth = async () => {
  //   state = uuidv4();
  //   localStorage.setItem('oauth-state', state);
  //   console.log(state);
  //   await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/state`, {
  //     "apiSource": "Tradier", 
  //     "state": state,
  //     "userId": localStorage.getItem('userId')
  //   });
  //   const callbackUrl = `${window.location.href}`;
  //   const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scope=${scopes}&state=${state}&redirect_uri=${callbackUrl}`;
  //   window.location.href = target;
  // };
  

  // const handleEtradeAuth = async (e) => {
  //   if(eTradeAccessToken.length == 0){
  //     return toast.error("Enter Access Token!")
  //   }
  //   setETradeLoading(true)

  //   await axios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth/verifier?code=${eTradeAccessToken}&userId=${localStorage.getItem("userId")}`)
  //     .then(async (res) => {
  //       if(res.status === 200) {
        
  //         setHasBrokerTokens(true)
  //         setAuthBroker("Etrade")
  //         toast("Successfully authenticated", { type: "success" });
  //         setHasBrokerTokens(true);
  //         setAuthBroker("Tradier")
  //         setETradeDialog(false)
  //         setETradeLoading(false)
  //         console.log(hasBrokerTokens);

  //         await axios
  //           .get(`${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` + localStorage.getItem('userId'))
  //             .then(res => {
  //               console.log(res.data)
  //               setAccountList(res.data.accountList)    
  //               setSelectedAccount(res.data.accountList[0]?.accountIdKey);
  //             })
  //             .catch(err => console.error(err));
  //       } else {
  //         toast("Authentication Failed", { type: "error" });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })

  //   // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;
    
  //   // window.open(target, "_blank");
  // };

  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  
  useEffect(() => {
    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }

    const getNews = async () => {
      await axios.get(
        `https://stocknewsapi.com/api/v1/category?section=general&items=8&page=1&token=${process.env.REACT_APP_NEWS_KEY}`
      )
      .then((response) => {
        setNewsData(response.data?.data?.splice(0,8))
      })
      .catch((error) => {
        toast.error(error?.data);
      })
    }
    getNews();
  }, []);
  console.log(newsData)

  // useEffect(() => {
  //   const sendAuthCode = async (code) => {
  //     state = localStorage.getItem('oauth-state');
  //     await axios
  //       .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/authcode`, {
  //         "user_id": localStorage.getItem('userId'),
  //         "api_source": "Tradier", // TODO: Needs to become dynamic
  //         "code": code,
  //         "state": state,
  //         "scope": scopes
  //       })
  //       .then(async (res) => {
  //         if(res.status === 200) {
  //           toast("Successfully authenticated", { type: "success" });
  //           setHasBrokerTokens(true);
  //           setAuthBroker("Tradier")
  //           console.log(hasBrokerTokens);
  //           await axios
  //             .get(`${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` + localStorage.getItem('userId'))
  //               .then(res => {
  //                 console.log(res.data)
  //                 setAccountList(res.data.accountList)    
  //                 setSelectedAccount(res.data.accountList[0]?.accountIdKey);
  //               })
  //               .catch(err => console.error(err));
  //         } else {
  //           toast("Authentication Failed", { type: "error" });
  //         }
  //       })
  //       .catch(err => console.error(err));
  //   };

  //   const query = new URLSearchParams(location.search);
  //   const code = query.get("code");

  //   if (code) {
  //     console.log("Code", code); // send code to backend receive access token
  //     sendAuthCode(code);
  //     localStorage.removeItem('oauth-state');
  //   }
  // }, [location]);

  
  useEffect(() => {
    if (btoData && btoData?.active && btoData?.expired && !btoStoreData) {
      console.log("BTO", btoData)
      let tempArray = btoData?.active?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        return [timestamp, "active", item];
      });

      btoData?.expired?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "expired", item]];
      });

      btoData?.sold?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "sold", item]];
      });

      tempArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));

      // Get the 5 most recent trades from the entire tempArray
      const mostRecentTrades = tempArray.slice(0, 5);
      setRecentTrades(mostRecentTrades);

      setIsLoading(false)

      // const currentDate = new Date().getTime();
      // let lastSevenDaysArray = tempArray?.filter((entry) => {
      //   Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
      //   return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
      // });
      // lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
      // setBtoStoreFilteredData(lastSevenDaysArray);
      setBtoStoreData(tempArray);
    }
  }, [btoData]);

  
  // useEffect(() => {
  //   switch (tableTimeScale) {
  //     case "year":
  //       if (btoStoreData) {
  //         let currentDate = new Date().getTime();
  //         let lastYearArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
  //           return currentDate - entry[0] <= 360 * 24 * 60 * 60 * 1000;
  //         });
  //         lastYearArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastYearArray);
  //       }
  //       break;
  //     case "month":
  //       if (btoStoreData) {
  //         let currentDate = new Date().getTime();
  //         let lastMonthArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
  //           return currentDate - entry[0] <= 29 * 24 * 60 * 60 * 1000;
  //         });
  //         lastMonthArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastMonthArray);
  //       }
  //       break;
  //     case "week":
  //       if (btoStoreData) {
  //         const currentDate = new Date().getTime();
  //         let lastSevenDaysArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
  //           return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
  //         });
  //         lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastSevenDaysArray);
  //       }
  //       break;
  //     case "all":
  //       if (btoStoreData) {
  //         let completeArray = btoStoreData.sort(
  //           (a, b) => new Date(b[0]) - new Date(a[0])
  //         );
  //         setBtoStoreFilteredData(completeArray); // Use all data for the graph
  //       }
  //       break;
  //   }
  // }, [tableTimeScale]);

  // console.log(brokerBalance)

  // const handleBrokerLogout = async () => {
  //   setIsLoading(true)
    
  //   await axios.delete(
  //     "https://backend-ums-stage-797b8d335ec5.herokuapp.com/oauth/token/" + localStorage.getItem('userId')
  //   )
  //   .then((response) => {
  //     console.log(response.data)
  //     setHasBrokerTokens(false)
  //     setAuthBroker(null)
  //     setAccountList(null)
  //     setSelectedAccount(null)
  //     setBrokerBalance(null)
  //     setIsPaperTrade(true);
  //     setIsLoading(false)
  //   })
  //   .catch((error) => {
  //     console.log(error.data)
  //     setIsLoading(false)
  //   })
  // }

  return (
    <div className="lg:h-screen flex flex-col lg:flex-row bg bg-cover bg-center">
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"dashboard"}
      />

      {/* {(eTradeDialog || instruction) && (
        <div
          onClick={() => {setETradeDialog(false)
            setInstruction(false)
          }}
          className={`fixed h-screen w-full bg-black bg-opacity-60 ${instruction ? "z-[45]" : "z-30"}  top-0 left-0
            ${eTradeLoading ? "pointer-events-none" : ""}
          `}
        />
      )}

      {eTradeDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            ETrade Login
            <img src={info} onClick={() => setInstruction(true)} className="size-5 cursor-pointer"/>
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
          <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div>

          <div className="w-full flex justify-center mt-6">
            <button
              className="border-2 border-tertiary border-opacity- 20 rounded-md mx-auto px-4 py-2 text-white font-semibold text-center flex items-center gap-2"
              onClick={() => window.open(`${process.env.REACT_APP_API_BASE_URL}/oauth/request_token?userId=${localStorage.getItem("userId")}`, "_blank")}
            >
              <img src={eTrade} className="size-6"/>
              Get Access Token
            </button> 
          </div>
          
          <div className="w-full h-[1px] mt-6 bg-white opacity-15" />
          <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Enter Access Token:</label>
              <input value={eTradeAccessToken} onChange={(e) => setETradeAccessToken(e.target.value)} className="bg-[#424242] px-2 py-1 rounded-md"/>
            </div>
            <div className="text-red-600 text-sm">
              NOTE: Logging in will automatically log you out of other broker services
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={eTradeLoading}
              onClick={() => setETradeDialog(false)}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={eTradeLoading}
              onClick={handleEtradeAuth}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {instruction && (
        <div className="w-[60%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[46] text-[#9b9b9b] bg-[#191919] rounded-lg py-6 px-6 h-[750px]">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            How to Login to ETrade?
            <img src={close} onClick={() => setInstruction(false)} className="size-5 cursor-pointer"/>
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

          <div className="overflow-y-scroll scrollbar h-[650px] mt-4">

            <div className="">
              <div className="text-xl font-semibold">Step 1</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-4">Select the "Get Access Token" option and head over to ETrade.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 2</div>
              <img src={auth} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-4">Log in to ETrades with the required account and once logged in, close this tab and head back to Alertisfy.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 3</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Select the "Get Access Token" option again.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 4</div>
              <img src={permission} className="mt-4 h-64 mx-auto" /> 
              <img src={code} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-2">Accept the platform conditions, copy the code which comes after and head back over to Alertsify.</div>
            </div>
            
            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 5</div>
              <img src={enterToken} className="mt-4 h-36 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Enter the access token and submit auth request.</div>
            </div>
          </div>
        </div>
      )} */}
        
      {(hasBrokerTokens !== null && hasDataBrokerTokens !== null) && !isLoading ? (
        <div
          className={`hid den lg:block ${
            bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
          } bg-cover bg-no-repeat bg-left px-4 lg:px-12 py-8 text-[15px] overflow-y-scroll scrollbar`}
          style={{ backgroundImage: `url(${bgLeft})` }}
        >
          {/* Top bar only for bigger screens  */}

          <DashHeader />

          <div className="md:mt-6 overflow- scroll scrollbar">
            <div className="flex flex-col md:flex-row justify-between gap-4 md:h-[472px]">
              {/* {hasBrokerTokens ? (
                <div className="hidden fle x flex-col w-full md:w-1/2 gap-4 md:h-[472px]">
                  <div className="bg-[#202020] p-6 rounded-[32px] md:h-1/2 border border-[#6c6c6c]">
                    <div className="flex gap-2 justify-between items-center text-[#A1A1A1] w-full">
                      <div className="flex items-center h-[23px]">
                        <div className="">{authBroker ? authBroker : ""}:</div>
                        <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#28A263]" />
                        <div className="text-[#28A263]">Connected</div>
                      </div>
                    </div>
                    <div className="mt-10 flex items-center justify-between">
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.totalEquity !== undefined ? "$" + Number(brokerBalance?.totalEquity)?.toFixed(2) : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Total Value</div>
                      </div>
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.availableFunds !== undefined ? "$" + Number(brokerBalance?.availableFunds)?.toFixed(2) : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Available Funds</div>
                      </div>
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.unsettledFunds !== undefined ? "$" + Number(brokerBalance?.unsettledFunds)?.toFixed(2) : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Unsettled Funds</div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4 md:mt-6">
                      <button className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg"
                        onClick={handleBrokerLogout}
                      >
                        Logout
                      </button>
                    </div>
                  </div> 
                  
                  <div className="flex flex-col bg-[#202020] p-6 rounded-[32px] md:h-1/2">
                    <div className="flex justify-between">
                      <div className="text-[#A1A1A1] text-lg">Paper Trades</div>
                      <div className="">
                        <div className="text-[32px] md:text-[40px] leading-none text-[#9B9B9B] font-bold">
                          {"$" + currentBalance || "$0.00"}
                        </div>
                        <div className="text-sm md:text-base text-tertiary">Current Balance</div>
                      </div>
                    </div>
                    <div className="text-sm md:text-base mt-4 text-[#A1A1A1]">
                      Start trading with real-time options insights for smarter,
                      faster decisions.
                    </div>
                    <div className="flex items-end justify-center mt-4 md:mt-auto">
                      <button onClick={() => navigate("/model")} className="border border-[#6E6E6E] rounded-lg py-2 px-3 text-tertiary">
                        Start Trading
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="hidden fle x flex-col gap-4 w-full md:w-1/2 h-full">
                  <div className="flex gap-2 bg-[#202020] p-6 rounded-[32px] h-1/2">
                    <div className="w-fit h-full scroll-y-auto scrollbar">
                      <img src={eTrade} onClick={() => setSelectedService("eTrade")} className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${selectedService === "eTrade" ? "border-2 border-tertiary [#424242]" : ""}`} />
                      <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]"/>
                      <img src={tradier} onClick={() => setSelectedService("tradier")} className={`size-10 p-1 rounded-lg cursor-pointer ${selectedService === "tradier" ? "border-2 border-tertiary [#424242]" : ""}`} />
                    </div>
                    <div className="w-[2px] bg-[#424242] h-full" />
                    <div className="w-full">
                      {selectedService === "tradier" ? (
                        <>
                          <div className="flex items-center h-[23px]">
                            <div className="text-[#A1A1A1]">Brokerage Account :</div>
                            <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                            <div className="text-[#F24E1E]">Not Connected</div>
                          </div>
                          <div className="flex flex-grow h-full justify-center items-center py-6">
                            <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                              <img src={tradier} className="size-6" />
                              <button
                                onClick={handleTradierAuth}
                                className="text-tertiary"
                              >
                                Sign In with Tradier
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex items-center h-[23px]">
                            <div className="text-[#A1A1A1]">Brokerage Account :</div>
                            <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                            <div className="text-[#F24E1E]">Not Connected</div>
                          </div>
                          <div className="flex flex-grow h-full justify-center items-center py-6">
                            <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                              <img src={eTrade} className="size-6" />
                              <button
                                onClick={() => setETradeDialog(true)}
                                className="text-tertiary"
                              >
                                Sign In with ETrade
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col bg-[#202020] p-6 rounded-[32px] h-1/2">
                    <div className="flex justify-between">
                      <div className="text-[#A1A1A1] text-lg">Paper Trades</div>
                      <div className="">
                        <div className="text-[40px] leading-none text-[#9B9B9B] font-bold">
                          {"$" + currentBalance || "$0.00"}
                        </div>
                        <div className="text-tertiary">Current Balance</div>
                      </div>
                    </div>
                    <div className="mt-8 text-[#A1A1A1]">
                      Start trading with real-time options insights for smarter,
                      faster decisions.
                    </div>
                  </div>
                </div>
              )} */}

              <div className="flex flex-col w-full md:w-1/2 gap-4 md:h-[472px]">
                <ModelAuth />
                <OrderAuth />
              </div>

              <div className="w-full md:w-1/2 bg-[#202020] p-6 rounded-[32px] gap-4 md: h-[472px]">
                <div className="text-[#A1A1A1] h-fit">Recent Updates</div>
                {newsData ? (
                  <div className="h-[94%] overflow-y-scroll scrollbar pr-4 mt-4">
                    {newsData?.map((item, index) => (
                      <div key={index} className="bg-[#1C1C1C] p-2 mb-4 flex gap-2 rounded-2xl">
                        <img src={item?.image_url} className="h-16"/>
                        <div className="flex flex-col justify-between w-full px-2">
                          <div className="text-[#9B9B9B] font-semibold text-[17px]">{item?.title}</div>
                          <a href={item?.news_url} target="blank" className="w-full text-right text-tertiary underline underline-offset-2">
                            Read More
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                ): (
                  <div className="flex w-full h-full items-center justify-center">
                    <Loader />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 bg-[#202020] p-6 rounded-[32px] h-[400px] ">
              <div className="flex justify-between items-center">
                <div className="text-lg text-[#A1A1A1]">Recent Trades</div>
                {/* <select
                  value={tableTimeScale}
                  onChange={(e) => setTableTimeScale(e.target.value)}
                  className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                >
                  <option className="bg-[#202020]" value="week">
                    Last 7 Days
                  </option>
                  <option className="bg-[#202020]" value="month">
                    Last Month
                  </option>
                  <option className="bg-[#202020]" value="year">
                    Last Year
                  </option>
                  <option className="bg-[#202020]" value="all">
                    All
                  </option>
                </select> */}
              </div>
              <div className="mt-6 overflow-y-scroll scrollbar h-[300px]">
                {/* {btoStoreFilteredData?.map((item, index) => ( */}
                {recentTrades?.map((item, index) => (
                  <div>
                    <Accordion index={index} item={item} page={"dash"}/>
                  </div>
                ))}
                {/* {btoStoreFilteredData?.length === 0 && ( */}
                {recentTrades?.length === 0 && (
                  <div className="flex w-full justify-center items-center h-48 text-[#7b7B7B] font-semibold text-xl">
                    No Bto's available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-[30%] -translate-y-[60%]">
          <Loader />
        </div>
      )}

    </div>
  );
};

export default DashboardHome;
