import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useModelStore = create(
  persist(
    (set) => ({
      selectedOptionArray: ['SPY'], // Initialize with a single value
      addRow: (ticker) =>
        set((state) => ({
          selectedOptionArray: [...state.selectedOptionArray, ticker],
        })),
      removeRow: (index) =>
        set((state) => ({
          selectedOptionArray: state.selectedOptionArray.filter(
            (_, i) => i !== index
          ),
        })),
      setSelectedOptionArray: (newArray) =>
        set({ selectedOptionArray: newArray }),
    }),
    {
      name: 'options-storage', // Local storage key
    }
  )
);

export default useModelStore;
