import React, { useState } from "react";
import { userStore } from "../../store/user";
import { Link } from "react-router-dom";
import {v4 as uuidv4} from "uuid"

import profile from "../../assets/defaultPicture.png";
import logo from "../../assets/alertsify.svg";
import Skeleton from "react-loading-skeleton";
import { getUserBtoModelData } from "../../API/bto";
import { toast } from "react-toastify";

const DashHeader = () => {
  const [showDialog, setShowDialog] = useState(false)
  
  const {
    userData,
    btoData,
    hasBrokerTokens,
    setHasBrokerTokens,
    authBrokerId,
    setBtoModelData,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    brokerBalance,
    isPaperTrade,
    setIsPaperTrade,
    tradeTypeLoader,
    setTradeTypeLoader,
    socket,
    wishlistData,
    setUniqueString,
    balanceLoader
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance,
  } = userData || {};

  function generateUniqueString() {
    return uuidv4();
  }

  const gentradeToken = localStorage.getItem("gentradeToken");
  
  console.log("isPaperTrade", isPaperTrade)

  const handleTradeTypeChange = async() => {
    setTradeTypeLoader(true)

    const randomString = generateUniqueString()
    setUniqueString(randomString)

    socket.emit("wishlist", {
      userId,
      isRealTrade: isPaperTrade, 
      wishlistData,
      changeId: randomString
    });
    
    let btoModelResponse;
    if(isPaperTrade){

      if(authBrokerId){
        btoModelResponse = await getUserBtoModelData(gentradeToken, !isPaperTrade, authBrokerId)
      } else {
        setTradeTypeLoader(false)
        return toast.error("Unable to switch to real trades. Please try again.")
      }
    } else{
      btoModelResponse = await getUserBtoModelData(gentradeToken, !isPaperTrade, authBrokerId)
    }
    const { activeBtos:activeModelBtos , processingBtos:processingModelBtos, expiredBtos:expiredModelBtos , soldBtos:soldModelBtos } = btoModelResponse.data || {};

    let expiredModelValues = [];
    if (expiredModelBtos?.length) {
      expiredModelValues = expiredModelBtos.map((d) => ({
        ...d,
        pnlText: "BTO has expired",
      }));
    }

    setBtoModelData({
      expired: expiredModelValues,
      active: activeModelBtos,
      processing: processingModelBtos,
      sold: soldModelBtos?.length
        ? soldModelBtos.map((d) => ({
            ...d,
            pnlText: "BTO has been sold out",
          }))
        : [],
    });

    //update isPaperTrade in the end
    setIsPaperTrade(!isPaperTrade)
    localStorage.setItem("paperTrade", !isPaperTrade);
    setTradeTypeLoader(false)
  }

  return (
    <div
      className={`lg:flex ${
        hasBrokerTokens ? "justify-between" : "justify-end"
      } items-center gap-4 mb-4`}
    >
      {showDialog && (
        <div className="fixed w-full h-screen left-0 top-0 z-40">
          <div className="absolute left-0 right-0 w-full h-full bg-black z-20" />
          <div className="absolute left-1/2 top-1/2 w-[80%] lg:w-auto transform -translate-x-1/2 -translate-y-1/2 z-30 bg-[#111111] p-6 rounded-lg text-white">
            {/* <div className="flex justify-end">

            </div> */}
            <div class="md:flex items-center">
              <div class="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                <img src={logo} alt="" />
              </div>
              <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                <p class="font-bold">Feature coming out soon....</p>
                <p class="text-sm text-gray-100 mt-1">Switching to real trades will be available soon</p>
              </div>
            </div>
            <div class="text-center md:text-right mt-4 md:flex md:justify-end">
              <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-black bg-red-600 green-800 text-white green-600 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={() => setShowDialog(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
      {hasBrokerTokens && (
        <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-center">
          <div
            className={`w-fit flex gap-3  [#C4C4C4]  border ${
              isPaperTrade
                ? "border- [#646464] text-tertiary"
                : "border-tertiary bg- tertiary text-[#111111]"
            } transition-all rounded-lg text-center px-2 py-1 text-lg sm `}
          >
            <label class="inline-flex gap-2 items-center mb- 5 py-[2px] cursor-pointer">
              <span className={`${isPaperTrade ? "text-tertiary" : "text-[#646464]" } ms -3 font-medium text-gray -900 dark:text-gray- 300 mr-1`}>
                Paper Trades
              </span>
              <input
                type="checkbox"
                checked={!isPaperTrade}
                onChange={handleTradeTypeChange}
                disabled={tradeTypeLoader}
                className="sr-only peer"
              />
              <div class="relative w-9 h-5 bg- gray-200 peer-focus:outline-none peer-focus:ring- 2 peer-focus:ring-white blue-300 dark:peer-focus:ring-white dark:checked:peer-focus:ring-black checked:peer-focus:ring-black blue-800 rounded-full peer dark:bg-[#646464] gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] before: peer- after:bg-white after: peer-checked:after:bg-black [#111111] after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-tertiary"></div>
              <span className={`${isPaperTrade ? "text-[#646464]" : "text-tertiary" } ms -3 text- sm font-medium text-gray -900 dark:text-gray- 300 ml-1`}>
                Real Trades
              </span>
            </label>
          </div>
{/* 
          <div className="w-fit flex gap-2 text-[#929292] tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 font-semibold">
            Broker Account:
            {accountList ? (
              <select
                className="text-white bg-transparent border-none outline-none px-2 py- 1 font-normal"
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                {accountList?.map((item, index) => (
                  <option value={item.accountIdKey} className="bg-black text-white p-2">
                    {item.accountId}
                  </option>
                ))}
              </select>
            ) : (
              <Skeleton
                baseColor="#424242"
                highlightColor="#202020"
                width="80px"
              />
            )}
          </div> */}
        </div>
      )}
      <div className="lg: flex flex-col lg:flex-row mt-2 lg:mt-0 justify-end gap-2 lg:gap-4">
        {hasBrokerTokens && !isPaperTrade && (
          <>
          
            <div className="w-fit flex gap-2 text-[#929292] tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 font-semibold">
              Broker Account:
              {accountList ? (
                <select
                  className="text-white bg-transparent border-none outline-none px-2 py- 1 font-normal"
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.target.value)}
                >
                  {accountList?.map((item, index) => (
                    <option value={item.accountIdKey} className="bg-black text-white p-2">
                      {item.accountId}
                    </option>
                  ))}
                </select>
              ) : (
                <Skeleton
                  baseColor="#424242"
                  highlightColor="#202020"
                  width="80px"
                />
              )}
            </div> 
          
            <div className="w-fit flex gap-2 text-[#929292] tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 font-semibold">
              Broker Account Balance:
              <div className="text-white font-normal">
                {!balanceLoader ? (
                  brokerBalance?.availableFunds || 0
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="60px"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {isPaperTrade && (
          <div className="w-fit flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Paper Trade Balance:
            <div className="text-white">{currentBalance}</div>
          </div>
        )}
        <div className="hidden lg:flex">
          {role == "admin" ? (
            <Link
              to={"/admin/userInfo"}
              className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
            >
              Admin Portal
            </Link>
          ) : (
            ""
          )}
          <img
            src={avatarUrl || profile}
            alt="profile"
            className="ml-6 border border-white rounded-full size-[34px]"
          />
        </div>
      </div>
    </div>
  );
};

export default DashHeader;
