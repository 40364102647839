import React, { useEffect, useState, memo } from "react";
import { toast } from "react-toastify";
import { sellFullBto, getUserBtoData } from "../API/bto";
import { userStore } from "../store/user";
import { Button, Empty, Popconfirm, Tabs } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { sendMessage } from "../API/discord";
import moment from "moment";
import PnlColumn from "./PnlColumn";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import RenderTable from "./RenderTable";

const BtoData = React.memo(() => {
  // const [socketLoading, setSocketLoading] = useState(true)
  // function alterSocketLoading(check) {
  //   setSocketLoading(check);
  // }
  const {
    btoModelData: { active = [], processing = [], expired = [], sold = [] },
    btoModelData,
    // btoData,
    // setBtoData,
    // setBtoLoading,
    // userData,
    btoLoading,
    isPaperTrade,
    orderTypeStc, 
    setOrderTypeStc,
    limitPriceStc,
    setLimitPriceStc,
    stopPriceStc,
    setStopPriceStc,
    timeInForceStc,
    setTimeInForceStc,
    socket,
    shiftBto
  } = userStore((state) => state);
  
  const [activeTab, setActiveTab] = useState("active");
  const [activeBtos, setActiveBtos] = useState([])
  const [processingBtos, setProcessingBtos] = useState([])
  // const [orderType, setOrderType] = useState("limit")
  // const [limitPrice, setLimitPrice] = useState()
  // const [stopPrice, setStopPrice] = useState()
  // const [timeInForce, setTimeInForce] = useState("day")

  // console.log(active)
  

    useEffect(() => {
      if (!socket) return;
      socket.on("pnl_stream", (message) => {
        if(message){
          let activeBto = [];
          Object.values(message)?.map((bto) => {
            // console.log(bto)
            activeBto.push(bto)
          })
          // console.log(activeBto)
          setActiveBtos(activeBto)
        }
      });
  
      socket.on("processing_orders", (message) => {
        if(message){
          let processingBtos = [];
          Object.values(message?.processingOrders)?.map((bto) => {
            // console.log(bto)
            processingBtos.push(bto)
          })
          // console.log(activeBto)
          setProcessingBtos(processingBtos)
        }
        
      });
    }, [socket]);

  return (
    <div className="w-full flex flex-col gap-3 my-4">
      <span className="text-[22.6px] font-medium text-tertiary ">STC</span>
      {/* {active?.length || expired?.length || sold?.length ? (
        <Tabs
          onChange={(key) => setActiveTab(key)}
          defaultActiveKey="active"
          items={[
            {
              label: `Active (${active?.length})`,
              key: "active",
              children: <RenderTable data={active} current={"Active"} />,
            },
            {
              label: `Sold (${sold?.length})`,
              key: "sold",
              children: <RenderTable data={sold} current={"Sold"} />,
            },
            {
              label: `Expired (${expired?.length})`,
              key: "expired",
              children: <RenderTable data={expired} current={"Expired"} />,
            },
          ]}
        />
      ) : btoLoading ? (
        <span className="flex items-center w-full justify-center text-lg md:text-3xl text-white">
          Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
        </span>
      ) : (
        !active?.length &&
        !expired?.length && <Empty description="No BTO Records Are Exist" />
      )}{" "} */}
      {(activeBtos?.length || processingBtos?.length || expired?.length || sold?.length) ? (
        <div>
          <div className="flex gap-6 border-b py-2 mb-4 text-sm">
            <div 
              className={`${activeTab === "active" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('active')}
            >
              Active {"(" + activeBtos?.length + ")" || "(" + 0 + ")"}
            </div>
            <div 
              className={`${activeTab === "processing" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('processing')}
            >
              Processing {"(" + processingBtos?.length + ")" || "(" + 0 + ")"}
            </div>
            <div 
              className={`${activeTab === "sold" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('sold')}
            >
              Sold {"(" + sold?.length + ")" || "(" + 0 + ")"}
            </div>
            <div 
              className={`${activeTab === "expired" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('expired')}
            >
              Expired {"(" + expired?.length + ")" || "(" + 0 + ")"}
            </div>
          </div>

          {activeTab === "active" && !isPaperTrade && (
            <>
              <div className="mt-4 flex gap-4 font-medium items-center text-[#7D7D7D]">
                <div className="flex gap-2">
                  <div>Order Type:</div>
                  <select
                    className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                    value={orderTypeStc}
                    onChange={(e) => {
                      setLimitPriceStc(0)
                      setStopPriceStc(0)
                      setTimeInForceStc("day")
                      setOrderTypeStc(e.target.value)
                    }}
                    >
                    <option value="limit">Limit</option>
                    <option value="market">Market</option>
                    <option value="stop">Stop</option>
                    <option value="stoplimit">StopLimit</option>
                  </select>
                </div>
                {(() => {
                  switch(orderTypeStc){
                    case "limit":
                      return (
                        <>
                          <div className="flex gap-2">
                            <div>Limit Price:</div>
                            <input
                              type="number"
                              className="bg-[#555555] px-2 py-[1px] outline-none text-white w-[130px] rounded-lg"
                              // defaultValue={wishlistData?.balance}
                              value={limitPriceStc}
                              step={0.01}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                  setLimitPriceStc(value); // Only update if input is valid
                                }
                              }}
                              // onBlur={(e) => handleBalanceChange(e)}
                              // onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                            />
                          </div>
                          <div className="flex gap-2">
                            <div>Time In Force:</div>
                            <select
                              className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                              value={timeInForceStc}
                              onChange={(e) => setTimeInForceStc(e.target.value)}
                            >
                              <option value="day">Day</option>
                              <option value="gtc">GTC</option>
                            </select>
                          </div>
                        </>
                      )
                    case "market":
                      return (
                        <>
                          <div className="flex gap-2">
                            <div>Time In Force:</div>
                            <select
                              className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                              value={timeInForceStc}
                              onChange={(e) => setTimeInForceStc(e.target.value)}
                              >
                              <option value="day">Day</option>
                            </select>
                          </div>
                        </>
                      )
                    case "stop":
                      return (
                        <>
                          <div className="flex gap-2">
                            <div>Stop Price:</div>
                            <input
                              type="number"
                              className="bg-[#555555] px-2 py-[1px] outline-none text-white w-[130px] rounded-lg"
                              // defaultValue={wishlistData?.balance}
                              value={stopPriceStc}
                              step={0.01}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                  setStopPriceStc(value); // Only update if input is valid
                                }
                              }}
                              // onBlur={(e) => handleBalanceChange(e)}
                              // onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                            />
                          </div>
                          <div className="flex gap-2">
                            <div>Time In Force:</div>
                            <select
                              className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                              value={timeInForceStc}
                              onChange={(e) => setTimeInForceStc(e.target.value)}
                            >
                              <option value="day">Day</option>
                              <option value="gtc">GTC</option>
                            </select>
                          </div>
                        </>
                      )
                    case "stoplimit":
                      return (
                        <>
                          <div className="flex gap-2">
                            <div>Stop Price:</div>
                            <input
                              type="number"
                              className="bg-[#555555] px-2 py-[1px] outline-none text-white w-[130px] rounded-lg"
                              // defaultValue={wishlistData?.balance}
                              value={stopPriceStc}
                              step={0.01}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                  setStopPriceStc(value); // Only update if input is valid
                                }
                              }}
                              // onBlur={(e) => handleBalanceChange(e)}
                              // onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                            />
                          </div>
                          <div className="flex gap-2">
                            <div>Limit Price:</div>
                            <input
                              type="number"
                              className="bg-[#555555] px-2 py-[1px] outline-none text-white w-[130px] rounded-lg"
                              // defaultValue={wishlistData?.balance}
                              value={limitPriceStc}
                              step={0.01}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                  setLimitPriceStc(value); // Only update if input is valid
                                }
                              }}
                              // onBlur={(e) => handleBalanceChange(e)}
                              // onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                            />
                          </div>
                          <div className="flex gap-2">
                            <div>Time In Force:</div>
                            <select
                              className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                              value={timeInForceStc}
                              onChange={(e) => setTimeInForceStc(e.target.value)}
                            >
                              <option value="day">Day</option>
                              <option value="gtc">GTC</option>
                            </select>
                          </div>
                        </>
                      )
                  }
                })()}
              </div>
              
              <div className="w-full h-[1px] bg-white bg-opacity-40 mt-4 mb-2"/>
            </>
          )}
          <RenderTable activeTab={activeTab} />

        </div>
      )
      :  btoLoading ? (
        <span className="flex items-center w-full justify-center text-lg md:text-3xl text-white">
          Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
        </span>
      ) : (
        !activeBtos?.length && !processingBtos.length && !sold.length &&
        !expired?.length && <Empty description="No BTO Records Exist" />
      )}
    </div>
  );
})

export default memo(BtoData);
