import moment from "moment";
import { getOptionContracts } from "./API/user";
import tickers from "./pages/User/popularTickers";
import axios from "axios";

export const removeDuplicatesByKey = (arr, key) => {
  return arr && arr.length
    ? arr.filter(
        (elem, index, arr) =>
          arr.findIndex((obj) => obj[key] === elem[key]) === index
      )
    : [];
};

export const getExpirationDatesOfTicker = async () => {
  try {
    const responses = await Promise.allSettled(
      tickers
        .slice(0, 5)
        .map((ticker) =>
          getOptionContracts({ ticker }, process.env.REACT_APP_POLYGON_API_KEY)
        )
    );

    console.log("Raw Expiry Dates", responses)

    let newExpirationDates = {};
    responses.forEach((response, index) => {
      newExpirationDates[tickers[index]] = removeDuplicatesByKey(
        response.value.data.results,
        "expiration_date"
      )?.map((ob) => ob.expiration_date);
    });

    console.log("Filtered Expiration Dates", newExpirationDates)
    return newExpirationDates;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


// export const getExpirationDatesOfTickerTest = async (tickers) => {
//   try {
//     const responses = await Promise.allSettled(
//       tickers
//         .slice(0, 5)
//         .map((ticker) =>
//           getOptionContracts({ ticker }, process.env.REACT_APP_POLYGON_API_KEY)
//         )
//     );

//     console.log("Raw Expiry Dates", responses)

//     let newExpirationDates = {};
//     responses.forEach((response, index) => {
//       newExpirationDates[tickers[index]] = removeDuplicatesByKey(
//         response.value.data.results,
//         "expiration_date"
//       )?.map((ob) => ob.expiration_date);
//     });

//     console.log("Filtered Expiration Dates", newExpirationDates)
//     return newExpirationDates;
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };

// export const fetchTickerValue = async (ticker) => {
//   try {
//     const response = await axios({
//       method: "get",
//       url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
//     });
//     if (response.status === 200 && response?.data?.c) {
//       return response?.data?.c?.toString();
//     }
//   } catch (error) {
//     toast(error, {
//       type: "error",
//     });

//     return "1000";
//   }
// }

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});