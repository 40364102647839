import React, { useEffect, useState, memo } from "react";
import { toast } from "react-toastify";
import { sellFullBto, getUserBtoModelData, getUserBtoData } from "../API/bto";
import { userStore } from "../store/user";
import { getUser } from "../API/user";
import { Button, Empty, Popconfirm, Tabs } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { sendMessage } from "../API/discord";
import moment from "moment";
import PnlColumn from "./PnlColumn";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { formatter } from "../helper";
import axios from "axios";
import LoaderSecondary from "./LoaderSecondary";
import Loader from "./Loader";

const RenderTable = React.memo(({ activeTab }) => {
  const navigate = useNavigate();

  const current = activeTab;
  const {
    // btoData: { active = [], expired = [], sold = [] },
    btoData,
    btoModelData: { active = [], processing = [], expired = [], sold = [] },
    btoModelData,
    setBtoData,
    setBtoModelData,
    setBtoLoading,
    userData,
    setUserData,
    btoLoading,
    pnlData,
    isPaperTrade,
    authBrokerId,
    orderTypeStc,
    limitPriceStc,
    stopPriceStc,
    timeInForceStc,
    selectedAccount,
    hasBrokerTokens,
    setBrokerBalance,
    socket,
    setLimitPriceStc,
    setStopPriceStc
  } = userStore((state) => state);
  

  const {
    avatarUrl,
    discordServers = [],
    id: userId,
    role,
    currentBalance
  } = userData || {};

  const [selectedIndex, setSelectedIndex] = useState()
  const [activeBtos, setActiveBtos] = useState([])
  const [processingBtos, setProcessingBtos] = useState([])
  const [activeLoading, setActiveLoading] = useState(true)
  const [processingLoading, setProcessingLoading] = useState(true)

  

  const data =
    activeTab === "active" ? activeBtos : activeTab === "processing" ? processingBtos : activeTab === "sold" ? sold : expired;
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem("baseURL");

  const [confirmOpens, setConfirmOpens] = useState({});

  const [qts, setQts] = useState(() =>
    data.reduce((acc, item, index) => {
      acc[index] = "";
      return acc;
    }, {})
  );

  const handleClearInputs = () => {
    const clearedQts = data.reduce((acc, item, index) => {
      acc[index] = "";
      return acc;
    }, {});
    setQts(clearedQts);
  };

  const [socketLoading, setSocketLoading] = useState(true);
  function alterSocketLoading(check) {
    setSocketLoading(check);
  }

  const token = localStorage.getItem("gentradeToken");
  const [messageLoading, setMessageLoading] = useState({});
  const { name } = userData || {};
  // const [activeTab, setActiveTab] = useState("active");
  const [loading, setLoading] = useState(false);
  // const [pnlData, setPnlData] = useState({});
  const [collapsible, setCollapsible] = useState({});

  const getESTOffset = (date) => {
    // Check if the date falls within daylight saving time
    // Daylight saving time starts on the second Sunday in March and ends on the first Sunday in November
    const year = date.year();
    const dstStart = moment([year, 2, 14 - new Date(year, 2, 1).getDay()]); // Second Sunday in March
    const dstEnd = moment([year, 10, 7 - new Date(year, 10, 1).getDay()]); // First Sunday in November

    return date.isBetween(dstStart, dstEnd, null, "[]") ? -4 * 60 : -5 * 60; // EDT is UTC-4, EST is UTC-5
  };

  const getESTMoment = () => {
    const now = moment();
    const estOffset = getESTOffset(now);
    return now.utcOffset(estOffset);
  };

  const tradingStartTime = moment()
    .utcOffset(getESTOffset(moment()))
    .set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
  const tradingEndTime = moment()
    .utcOffset(getESTOffset(moment()))
    .set({ hour: 16, minute: 0, second: 0, millisecond: 0 });

  const isWithinTradingHours = () => {
    const now = getESTMoment();
    const dayOfWeek = now.day();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5; // Monday to Friday
    return isWeekday && now.isBetween(tradingStartTime, tradingEndTime);
  };

  const updateUserInfo = async () => {
    try {
      const response = await getUser(gentradeToken);
      setUserData(response?.data);
      // localStorage.removeItem("btoMetaData");
      // localStorage.removeItem("cachedDiscordChannels");
    } catch (err) {
      console.log(err);
      toast(
        err?.response?.data || "Something went wrong, please try again later.",
        { type: "error" }
      );
      localStorage.clear();
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  };

  const updatedBtoMetaData = async () => {
    try {
      setBtoLoading(true);
      const btoResponse = await getUserBtoData(token);
      const { activeBtos, expiredBtos, soldBtos } = btoResponse.data || {};
      let expiredValues = [];
      if (expiredBtos?.length) {
        expiredValues = expiredBtos.map((d) => ({
          ...d,
          pnlText: "BTO has expired",
        }));
      }

      setBtoData({
        expired: expiredValues,
        active: activeBtos,
        sold: soldBtos?.length
          ? soldBtos.map((d) => ({
              ...d,
              pnlText: "BTO has been sold out",
            }))
          : [],
      });
      
      //Storing BTOs for model (either real or paper)

      const btoModelResponse = await getUserBtoModelData(gentradeToken, isPaperTrade, authBrokerId)
      const { activeBtos:activeModelBtos , processingBtos:processingModelBtos, expiredBtos:expiredModelBtos , soldBtos:soldModelBtos } = btoModelResponse.data || {};

      let expiredModelValues = [];
      if (expiredModelBtos?.length) {
        expiredModelValues = expiredModelBtos.map((d) => ({
          ...d,
          pnlText: "BTO has expired",
        }));
      }

      setBtoModelData({
        expired: expiredModelValues,
        active: activeModelBtos,
        processing: processingModelBtos,
        sold: soldModelBtos?.length
          ? soldModelBtos.map((d) => ({
              ...d,
              pnlText: "BTO has been sold out",
            }))
          : [],
      });

      setBtoLoading(false);
    } catch (err) {
      console.log(err);
      toast(
        err?.response?.data || "Please refresh to load the latest information",
        { type: "error" }
      );
      setBtoLoading(false);
    }
  };

  const handleSellFullBto = async (item, index) => {
    // if (!isWithinTradingHours()) {
    //   return toast.error("Market closed right now");
    // }
    try {
      setLoading(true);
      setConfirmOpens((prevValues) => ({
        ...prevValues,
        [index]: true,
      }));
      const newBtos = [...btoModelData[activeTab]];
      const soldBtos = [...btoModelData["sold"]];
      let {
        discordServerId,
        discordChannelId,
        appendText = "",
      } = JSON.parse(localStorage.getItem("btoMetaData") || `{}`);
      const state = userStore.getState();
      // const pnlValue = JSON.parse(localStorage.getItem("pnlData") || `{}`);
      const pnlValue = state.pnlData || `{}`;

            
      let realTradeConfig = {};

      if(!isPaperTrade){
        switch(orderTypeStc){
          case "limit":
            if(!limitPriceStc){
              return toast.error("Enter Limit Price")
            } else if(limitPriceStc == 0){
              return toast.error("Limit Price cannot be 0")
            }
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderLimitPrice: limitPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "market":
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "stop":
            if(!stopPriceStc){
              return toast.error("Enter Stop Price")
            } else if(stopPriceStc == 0){
              return toast.error("Stop Price cannot be 0")
            }
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderStopPrice: stopPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "stoplimit":
            if(!limitPriceStc){
              return toast.error("Enter Limit Price")
            } else if(limitPriceStc == 0){
              return toast.error("Limit Price cannot be 0")
            } else if(!stopPriceStc){
              return toast.error("Enter Stop Price")
            } else if(stopPriceStc == 0){
              return toast.error("Stop Price cannot be 0")
            }
            realTradeConfig = {
              brokerOrderType: "stopLimit",
              brokerOrderLimitPrice: limitPriceStc,
              brokerOrderStopPrice: stopPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
        }
        
        if(!selectedAccount){
          return toast.error("Account Details or Broker Id not available. Please refresh and try again!")
        }
      }

      
      const cachedDiscordChannels = JSON.parse(
        localStorage.getItem("cachedDiscordChannels") || `{}`
      );
      const discordChannels = cachedDiscordChannels[discordServerId];

      const response = await sellFullBto(token, newBtos[index].id, {
        serverId: discordServerId,
        channelId: discordChannelId,
        appendText,
        quote: pnlValue[item.id]?.quote,
        qty: parseInt(item?.remainingQty),
        discordInfo: {
          server: discordServers.find((ds) => ds.id == discordServerId),
          channel: discordChannels.find((ds) => ds.id == discordChannelId),
        },
      });
      soldBtos.unshift(response.data.record);
      newBtos.splice(index, 1);
      setBtoModelData({ [activeTab]: newBtos, sold: soldBtos });
      setLoading(false);
      handleClearInputs();
      setConfirmOpens({ ...confirmOpens, [index]: false });
      toast(response.data.msg, { type: "success" });

      // Update Balance
      if(!isPaperTrade && hasBrokerTokens && selectedAccount){
        const updateBalance = async () => {
          await axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/broker/account_balance/` +
                localStorage.getItem("userId") + "/" + selectedAccount
            )
            .then((res) => {
              console.log(res.data);
              setBrokerBalance(res.data);
            })
            .catch((err) => console.error(err));
        };
        updateBalance();
      }

      updatedBtoMetaData();
      updateUserInfo();
      setCollapsible((prevValue) => ({ ...prevValue, [index]: false }));
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast("Something went wrong, please try again later", { type: "error" });
    }
  };

  const handleSendMessage = async (item, index) => {
    // if (!isWithinTradingHours()) {
    //   return toast.error("Market closed right now");
    // }
    // console.log(item);
    // return;
    try {
      let {
        discordServerId,
        discordChannelId,
        appendText = "",
      } = JSON.parse(localStorage.getItem("btoMetaData") || `{}`);
      if (!discordServerId)
        return toast("Please select the discord server and channel");
      const state = userStore.getState();
      // const pnlValue = JSON.parse(localStorage.getItem("pnlData") || `{}`);
      const pnlValue = state.pnlData || `{}`;
      const newBtos = [...btoModelData[activeTab]];
      const message = `STC ${qts[index] || 0} ${item.ticker} ${
        item.strikePrice
      }${item?.contractType?.slice(0, 1).toUpperCase()} ${moment(
        item.expirationDate,
        "YYYY-MM-DD"
      ).format("MM-DD-YYYY")} @${
        pnlValue[item.id]?.quote || item.quote
      } ${appendText}`;

      const cachedDiscordChannels = JSON.parse(
        localStorage.getItem("cachedDiscordChannels") || `{}`
      );
      const discordChannels = cachedDiscordChannels[discordServerId];
      
      let realTradeConfig = {};

      if(!isPaperTrade){
        switch(orderTypeStc){
          case "limit":
            if(!limitPriceStc && limitPriceStc == 0){
              return toast.error("Limit Price cannot be 0")
            } else if(!limitPriceStc){
              return toast.error("Enter Limit Price")
            }
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderLimitPrice: limitPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "market":
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "stop":
            if(!stopPriceStc){
              return toast.error("Enter Stop Price")
            } else if(stopPriceStc == 0){
              return toast.error("Stop Price cannot be 0")
            }
            realTradeConfig = {
              brokerOrderType: orderTypeStc,
              brokerOrderStopPrice: stopPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
          case "stoplimit":
            if(!limitPriceStc){
              return toast.error("Enter Limit Price")
            } else if(limitPriceStc == 0){
              return toast.error("Limit Price cannot be 0")
            } else if(!stopPriceStc){
              return toast.error("Enter Stop Price")
            } else if(stopPriceStc == 0){
              return toast.error("Stop Price cannot be 0")
            }
            realTradeConfig = {
              brokerOrderType: "stopLimit",
              brokerOrderLimitPrice: limitPriceStc,
              brokerOrderStopPrice: stopPriceStc,
              brokerOrderTimeInForce: timeInForceStc
            }
            break;
        }
        
        if(!selectedAccount){
          return toast.error("Account Details or Broker Id not available. Please refresh and try again!")
        }
      }
  
      setMessageLoading((prevData) => ({ ...prevData, [index]: true }));

      await sendMessage(
        {
          serverId: discordServerId,
          channelId: discordChannelId,
          message,
          orderType: "stc",
          qty: parseInt(qts[index]),
          btoId: newBtos[index].id,
          quote: pnlValue[item.id]?.quote,
          discordInfo: {
            server: discordServers.find((ds) => ds.id == discordServerId),
            channel: discordChannels.find((ds) => ds.id == discordChannelId),
          },
          ticker: item?.ticker,
          strikePrice: parseFloat(item?.strikePrice),
          contractType: item?.contractType,
          expirationDate: item?.expirationDate,
          
          forcePaperMoneyTrade: isPaperTrade,
          brokerAccountId: selectedAccount,
          ...realTradeConfig
        },
        token
      )
      .then((response) => {
        toast(response.data.msg, { type: "success" });
        updatedBtoMetaData();
        updateUserInfo();
        setMessageLoading((prevData) => ({ ...prevData, [index]: false }));
        if (response.data.newData?.status == "active") {
          setQts((prevData) => ({ ...prevData, [index]: "" }));
          newBtos[index] = response.data.newData;
        } else newBtos.splice(index, 1);

        setBtoModelData({
          ...(response.data.newData?.status == "active"
            ? { [activeTab]: newBtos }
            : { sold: [response.data.newData, ...sold], active: newBtos }),
        });

        // Update Balance
        if(!isPaperTrade && hasBrokerTokens && selectedAccount){
          const updateBalance = async () => {
            await axios
              .get(
                `${process.env.REACT_APP_API_BASE_URL}/broker/account_balance/` +
                  localStorage.getItem("userId") + "/" + selectedAccount
              )
              .then((res) => {
                console.log(res.data);
                setBrokerBalance(res.data);
              })
              .catch((err) => console.error(err));
          };
          updateBalance();
        }
      
      })
      .catch((error) => {
        
        toast.error("Something went wrong while processing the order. Please try again")
        setMessageLoading((prevData) => ({ ...prevData, [index]: false }));
        console.log(error.data)
      })
    } catch (err) {
      console.log(err);
      const errorMessage =
      err.response?.data?.error || // Error from server
      err.response?.data?.error?.msg || // Nested error message
      "Something went wrong. Please try again later."; // Fallback message

      setMessageLoading((prevData) => ({ ...prevData, [index]: false }));
      toast(
        err?.response?.status == 400
          ? "please give all the necessary fields"
          : errorMessage,
        { type: "error" }
      );
    }
  };

  const [socketPnlData, setSocketPnlData] = useState({});

  useEffect(() => {
    if (!socket) return;
    socket.on("pnl_stream", (message) => {
      if(message){
        let activeBto = [];
        Object.values(message)?.map((bto) => {
          // console.log(bto)
          activeBto.push(bto)
        })
        // console.log(activeBto)
        setActiveBtos(activeBto)
        setActiveLoading(false)
      }
      if (activeTab == "expired") setSocketPnlData({});
      else {
        setSocketPnlData(message);
        // localStorage.setItem("pnlData", JSON.stringify(message)); // storing the pnlData in localStorage to get the current quote in BtoData componenet while selling the contracts
        // setPnlData(message)
        // alterSocketLoading(false);
      }
    });

    socket.on("processing_orders", (message) => {
      if(message){
        let processingBtos = [];
        Object.values(message?.processingOrders)?.map((bto) => {
          // console.log(bto)
          processingBtos.push(bto)
        })
        // console.log(activeBto)
        setProcessingBtos(processingBtos)
        setProcessingLoading(false)
      }
      
    });
  }, [socket]);

  return (activeTab === "active" ) && (activeLoading) ?
      <span className="mt-16 flex items-center w-full justify-center text-lg md:text-3xl text-white">
        Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
      </span>
    : (activeTab === "processing" ) && (processingLoading) ?
      <span className="mt-16 flex items-center w-full justify-center text-lg md:text-3xl text-white">
        Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
      </span>
    : data?.length ? (
    <>
      {(activeTab === "active") ? (
        <div className="hidden md:flex gap-2">
          <div className="w-[38%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              <div className="pb-2 w-[9%]">Type</div>
              <div className="pb-2 w-[12%]">Ticker</div>
              <div className="pb-2 w-[12%]">Strike</div>
              <div className="pb-2 w-[22%]">Expiration Date</div>
              <div className="pb-2 w-[25%]">Date Alerted</div>
              <div className="pb-2 w-[15%]">Entry</div>
            </div>
            <div className="flex flex-col gap-3">
              {activeBtos.map((item, index) => {
                const date = moment(item.createdAt).format(
                  "MM-DD-YYYY HH:mm:ss"
                );
                
                const pnlValue = socketPnlData[item.id] || {};
                return (
                  <div
                    key={index}
                    className={`flex justify-between gap-2 text-[#929292] h-16 w-full
                      ${index === selectedIndex ? "border- y border-y-tertiary " : ""}
                    `}
                    onClick={() => {
                      // setSelectedIndex(index)
                      if(!isPaperTrade){
                          const value = "pnl" in pnlValue
                            ? ` ${pnlValue?.quote}`
                            : item?.selling_quote
                              ? item?.selling_quote
                              : 0
                        
                          switch(orderTypeStc){
                            case "limit":
                              setLimitPriceStc(Number(value))
                              break;
                            case "stop":
                              setStopPriceStc(Number(value))
                              break;
                            case "stoplimit":
                              setStopPriceStc(Number(value))
                              setLimitPriceStc(Number(value))
                              break;
                          }
                      }
                    }}
                  >
                    {/* Type */}
                    <div className="py-1 text-center w-[9%]">
                      <div 
                        className={`rounded-md px- 2 py- 1 font-semibold w-[52px] mx-auto px-2 [97px] text-center
                          ${item?.isPaperMoney ?  "bg-[#b2c1c1] text-black white" : "bg-[#00c896] text-black"}
                        `}
                      >
                        {item?.isPaperMoney ? "Paper" : "Real"}
                      </div>
                    </div>
                    {/* Ticker */}
                    <div className="py-1 text-center w-[12%]">
                      <div className="">{item?.ticker}</div>
                    </div>
                    {/* Strike Price */}
                    <div className="py-1 text-center w-[12%]">
                      <div className="">{item?.strikePrice}</div>
                    </div>
                    {/* Expiration Date*/}
                    <div className="py-1 text-center w-[22%]">
                      <div className="">{item?.expirationDate}</div>
                    </div>
                    {/* Date */}
                    <div className="py-1 text-center w-[25%]">
                      <div className="">{date}</div>
                    </div>
                    {/* Entry  */}
                    <div className="py-1 text-center w-[15%]">
                      <div className="">{item?.quote}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[15%]">
            <PnlColumn
              activeTab={activeTab}
              socketLoading={socketLoading}
              alterSocketLoading={alterSocketLoading}
              activeBtos={activeBtos}
            />
          </div>

          <div className="w-[47%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              {/* <div className="pb-2 w-[17%]">Discord Info</div> */}
              <div className="pb-2 w-[15%]">Qty left/total</div>
              <div className="pb-2 w-[24%]">STC Qty</div>
              {/* <th className="pb-2 w-[5%]">PT/SL</th> */}
              <div className="pb-2 w-[35%]">STC Alert</div>
              <div className="pb-2 w-[15%]"> </div>
            </div>
            <div className="flex flex-col gap-3">
              {activeBtos?.map((item, index) => {
                const pnlValue = pnlData[item.id] || {};
                const message = `${name}:- STC ${qts[index] || 0} ${
                  item.ticker
                } ${item.strikePrice}${item?.contractType
                  ?.slice(0, 1)
                  .toUpperCase()} ${moment(
                  item.expirationDate,
                  "YYYY-MM-DD"
                ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;

                const pnlLiveValue = socketPnlData[item.id] || {};
                return (
                  <div
                    key={index}
                    className={`flex gap-2 justify-between text-[#929292] h-16 w-full
                      ${index === selectedIndex ? "border- y border-y-tertiary " : ""}
                    `}
                    onClick={() => {
                      // setSelectedIndex(index)
                      if(!isPaperTrade){
                          const value = "pnl" in pnlLiveValue
                            ? ` ${pnlLiveValue?.quote}`
                            : item?.selling_quote
                              ? item?.selling_quote
                              : 0
                        
                          switch(orderTypeStc){
                            case "limit":
                              setLimitPriceStc(Number(value))
                              break;
                            case "stop":
                              setStopPriceStc(Number(value))
                              break;
                            case "stoplimit":
                              setStopPriceStc(Number(value))
                              setLimitPriceStc(Number(value))
                              break;
                          }
                      }
                    }}
                  >
                    {/* Discord Info */}
                    {/* <div className="py-1 text-center w-[17%] flex flex-col ">
                      {item.discord_server_name &&
                      item?.discord_channel_name ? (
                        <>
                          <span>{item?.discord_server_name}</span>{" "}
                          <span>{item?.discord_channel_name}</span>
                        </>
                      ) : (
                        "NA"
                      )}
                    </div> */}
                    {/* Qty left / total */}
                    <div className="py-1 pl-1 w-[15%] text-center">{`${
                      item.remainingQty || 0
                    }/${item.totalQty}`}</div>
                    {/* STC Qty */}
                    <div className="py-1 p- w-[24%] text-center">
                      <div className={`flex justify-center gap-2 
                          ${activeTab === "processing" ? " pointer-events-none" : ""}
                        `}>
                        <input
                          disabled={
                            activeTab == "expired" ||
                            activeTab == "processing" ||
                            !item.remainingQty ||
                            socketLoading
                          }
                          type="text"
                          value={qts[index]}
                          className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
                          onChange={(e) =>
                            setQts((prevData) => ({
                              ...prevData,
                              [index]: e.target.value,
                            }))
                          }
                          onBlur={(e) => {
                            const value = e.target.value
                              ? parseInt(e.target.value)
                              : "";
                            if (!value) return;

                            if (value > item.remainingQty) {
                              toast(`You don've have ${value} qty`);
                              setQts((prevData) => ({
                                ...prevData,
                                [index]: "",
                              }));
                            }
                          }}
                        />
                        <button
                          className={`bg-[#EF4444] [#424242] text-white tertiary px-2 py-1 rounded-md ${
                            !qts[index] ||
                            messageLoading[index] ||
                            !item.remainingQty
                              ? "cursor-not-allowed opacity-45"
                              : "cursor-pointer"
                          }`}
                          title={
                            !item.remainingQty
                              ? "No quantites are left"
                              : !qts[index]
                              ? "Please add sell quantity"
                              : messageLoading[index]
                              ? "Please wait for STC process"
                              : ""
                          }
                          onClick={() =>
                            qts[index] &&
                            activeTab != "expired" &&
                            handleSendMessage(item, index)
                          }
                        >
                          {messageLoading[index] ? (
                            <div className="flex items-center text-white gap-1">
                              Sending{" "}
                              <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                            </div>
                          ) : (
                            "Sell"
                          )}
                        </button>
                      </div>
                    </div>
                    {/* STC Alert */}
                    <div className="py-1 p- w-[35%] text-center">
                      <span
                        type="text"
                        className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
                      >
                        {message}
                      </span>
                    </div>

                    {/* Actions  */}
                    {(activeTab == "active" || activeTab == "processing" )? (
                      <div
                        className={`pb-2 pl-2 text-center w-[15%] p-2 text-white
                        ${socketLoading ? "pointer-events-none" : ""}  
                      `}
                      >
                        <Popconfirm
                          open={confirmOpens[index]}
                          title="Are you sure that you want to sell this one.?"
                          okButtonProps={{
                            type: "primary",
                            danger: true,
                            loading,
                            htmlType: "button",
                          }}
                          onCancel={() =>
                            setConfirmOpens((prevValues) => ({
                              ...prevValues,
                              [index]: false,
                            }))
                          }
                          onConfirm={() =>
                            socketLoading ? "" : handleSellFullBto(item, index)
                          }
                        >
                          <div
                            className={`bg-red-500 text-white px-1 rounded-lg py-1 cursor-pointer
                              ${activeTab === "processing" ? "bg-gray-700 pointer-events-none" : "bg-red-500"}  
                            `}
                            title="Click to sell the full BTO's quantity"
                            onClick={() =>
                              setConfirmOpens((prevValues) => ({
                                ...prevValues,
                                [index]: true,
                              }))
                            }
                          >
                            {confirmOpens[index] ? "Selling" : "Sell All/Close"}
                          </div>
                        </Popconfirm>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (activeTab === "processing") ? (
        <div className="hidden md:flex gap-2">
          <div className="w-[53%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              <div className="pb-2 w-[9%]">Type</div>
              <div className="pb-2 w-[12%]">Ticker</div>
              <div className="pb-2 w-[12%]">Strike</div>
              <div className="pb-2 w-[22%]">Expiration Date</div>
              <div className="pb-2 w-[25%]">Date Alerted</div>
              <div className="pb-2 w-[15%]">Entry</div>
            </div>
            <div className="flex flex-col gap-3">
              {processingBtos.map((item, index) => {
                const date = moment(item.createdAt).format(
                  "MM-DD-YYYY HH:mm:ss"
                );
                const pnlValue = socketPnlData[item.id] || {};
                return (
                  <div
                    key={index}
                    className={`flex justify-between gap-2 text-[#929292] h-16 w-full
                      ${index === selectedIndex ? "border- y border-y-tertiary " : ""}
                    `}
                    onClick={() => {
                      // setSelectedIndex(index)
                      if(!isPaperTrade){
                          const value = "pnl" in pnlValue
                            ? ` ${pnlValue?.quote}`
                            : item?.selling_quote
                              ? item?.selling_quote
                              : 0
                        
                          switch(orderTypeStc){
                            case "limit":
                              setLimitPriceStc(Number(value))
                              break;
                            case "stop":
                              setStopPriceStc(Number(value))
                              break;
                            case "stoplimit":
                              setStopPriceStc(Number(value))
                              setLimitPriceStc(Number(value))
                              break;
                          }
                      }
                    }}
                  >
                    {/* Type */}
                    <div className="py-1 text-center w-[9%]">
                      <div 
                        className={`rounded-md px- 2 py- 1 font-semibold w-[52px] mx-auto px-2 [97px] text-center
                          ${item?.isPaperMoney ?  "bg-[#b2c1c1] text-black white" : "bg-[#00c896] text-black"}
                        `}
                      >
                        {item?.isPaperMoney ? "Paper" : "Real"}
                      </div>
                    </div>
                    {/* Ticker */}
                    <div className="py-1 text-center w-[12%]">
                      <div className="">{item?.ticker}</div>
                    </div>
                    {/* Strike Price */}
                    <div className="py-1 text-center w-[12%]">
                      <div className="">{item?.strikePrice}</div>
                    </div>
                    {/* Expiration Date*/}
                    <div className="py-1 text-center w-[22%]">
                      <div className="">{item?.expirationDate}</div>
                    </div>
                    {/* Date */}
                    <div className="py-1 text-center w-[25%]">
                      <div className="">{date}</div>
                    </div>
                    {/* Entry  */}
                    <div className="py-1 text-center w-[15%]">
                      <div className="">{item?.entryQuote}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="w-[15%]">
            <PnlColumn
              activeTab={activeTab}
              socketLoading={socketLoading}
              alterSocketLoading={alterSocketLoading}
              processingBtos={processingBtos}
            />
          </div> */}

          <div className="w-[47%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              {/* <div className="pb-2 w-[17%]">Discord Info</div> */}
              <div className="pb-2 w-[15%]">Qty</div>
              <div className="pb-2 w-[24%]">Contract Type</div>
              <div className="pb-2 w-[24%]">Order Type</div>
              {/* <th className="pb-2 w-[5%]">PT/SL</th> */}
              <div className="pb-2 w-[35%]">Broker Status</div>
            </div>
            <div className="flex flex-col gap-3">
              {processingBtos?.map((item, index) => {
                const pnlValue = pnlData[item.id] || {};
                const message = `${name}:- STC ${qts[index] || 0} ${
                  item.ticker
                } ${item.strikePrice}${item?.contractType
                  ?.slice(0, 1)
                  .toUpperCase()} ${moment(
                  item.expirationDate,
                  "YYYY-MM-DD"
                ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;

                const pnlLiveValue = socketPnlData[item.id] || {};
                return (
                  <div
                    key={index}
                    className={`flex gap-2 justify-between text-[#929292] h-16 w-full
                      ${index === selectedIndex ? "border- y border-y-tertiary " : ""}
                    `}
                    onClick={() => {
                      // setSelectedIndex(index)
                      if(!isPaperTrade){
                          const value = "pnl" in pnlLiveValue
                            ? ` ${pnlLiveValue?.quote}`
                            : item?.selling_quote
                              ? item?.selling_quote
                              : 0
                        
                          switch(orderTypeStc){
                            case "limit":
                              setLimitPriceStc(Number(value))
                              break;
                            case "stop":
                              setStopPriceStc(Number(value))
                              break;
                            case "stoplimit":
                              setStopPriceStc(Number(value))
                              setLimitPriceStc(Number(value))
                              break;
                          }
                      }
                    }}
                  >
                    {/* Discord Info */}
                    {/* <div className="py-1 text-center w-[17%] flex flex-col ">
                      {item.discord_server_name &&
                      item?.discord_channel_name ? (
                        <>
                          <span>{item?.discord_server_name}</span>{" "}
                          <span>{item?.discord_channel_name}</span>
                        </>
                      ) : (
                        "NA"
                      )}
                    </div> */}
                    {/* Qty left / total */}
                    <div className="py-1 pl-1 w-[15%] text-center">{`${item.qty}`}</div>
                    <div className="pb-2 w-[24%] capitalize">{`${item.contractType}`}</div>
                    <div className="pb-2 w-[24%] uppercase ">{`${item.type}`}</div>
                    {/* STC Alert */}
                    <div className="py-1 p- w-[35%] text-center capitalize">{`${item.brokerStatus}`}</div>

                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="hidden md:flex gap-2">
          <div className="w-[100%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              <div className="pb-2 w-[8%]">Type</div>
              <div className="pb-2 w-[6%]">Ticker</div>
              <div className="pb-2 w-[6%]">Strike</div>
              <div className="pb-2 w-[6%]">Qty</div>
              <div className="pb-2 w-[13%]">Expiration Date</div>
              <div className="pb-2 w-[8%]">Entry</div>
              <div className="pb-2 w-[8%]">Exit</div>
              <div className="pb-2 w-[10%]">PnL | PnL%</div>
              <div className="pb-2 w-[10%]">Trade Duration</div>
              <div className="pb-2 w-[15%]">STC History</div>
              <div className="pb-2 w-[8%]">Trade Value</div>
            </div>
            <div className="flex flex-col gap-3">
              {data.map((item, index) => {
                const date = moment(item.created_at).format(
                  "MM-DD-YYYY HH:mm:ss"
                );
                return (
                  <div
                    key={index}
                    className="flex justify-between gap-2 text-[#929292] h-16 w-full"
                  >
                    
                    {/* Type */}
                    <div className="py-1 text-center w-[8%]">
                      <div 
                        className={`rounded-md px- 2 py- 1 font-semibold w-[52px] fit mx-auto px-2 [97px] text-center
                          ${item?.isPaperMoney ?  "bg-[#b2c1c1] text-black white" : "bg-[#00c896] text-black"}
                        `}
                      >
                        {item?.isPaperMoney ? "Paper" : "Real"}
                      </div>
                    </div>
                    {/* Ticker */}
                    <div className="py-1 text-center w-[6%]">
                      <div className="">{item?.ticker}</div>
                    </div>
                    {/* Strike Price */}
                    <div className="py-1 text-center w-[6%]">
                      <div className="">{item?.strikePrice}</div>
                    </div>
                    {/* Quantity */}
                    <div className="py-1 text-center w-[6%]">
                      <div className="">{item?.qty}</div>
                    </div>
                    {/* Expiration Date*/}
                    <div className="py-1 text-center w-[13%]">
                      <div className="">{item?.expirationDate}</div>
                    </div>
                    {/* Entry  */}
                    <div className="py-1 text-center w-[8%]">
                      <div className="">{item?.quote}</div>
                    </div>
                    {/* Exit  */}
                    <div
                      className={`py-1 text-center w-[8%]
                        ${
                          item?.selling_quote > item?.quote
                            ? "text-green-500"
                            : item?.selling_quote === item?.quote
                            ? "text-yellow-500"
                            : item?.selling_quote < item.quote && "text-red-500"
                        }
                      `}
                    >
                      <div className="">{item?.selling_quote}</div>
                    </div>
                    {/* PnL - PnL% */}
                    <div
                      className={`py-1 text-center w-[10%]
                        ${
                          item?.selling_quote > item?.quote
                            ? "text-green-500"
                            : item?.selling_quote === item?.quote
                            ? "text-yellow-500"
                            : item?.selling_quote < item.quote && "text-red-500"
                        }
                      `}
                    >
                      <div className="">
                        {`  ${
                          item?.pnl_percent !== undefined
                            ? item?.pnl_percent + "%"
                            : "-"
                        } | ${
                          item?.pnl !== undefined
                            ? formatter.format(item?.pnl)
                            : "-"
                        }`}
                      </div>
                    </div>
                    {/* Trade Duration */}
                    <div className="py-1 text-center w-[10%]">
                      <div className="">{item?.trade_duration}</div>
                    </div>
                    {/* STC History */}
                    <div className="py-1 text-center w-[15%] text-ellipsis">
                      <div className="">{item?.stc_array}</div>
                    </div>
                    {/* Trade Value */}
                    <div
                      className={`py-1 text-center w-[8%]
                        ${
                          item?.selling_quote > item?.quote
                            ? "text-green-500"
                            : item?.selling_quote === item?.quote
                            ? "text-yellow-500"
                            : item?.selling_quote < item.quote && "text-red-500"
                        }
                      `}
                    >
                      <div className="">${item?.stc_trade_value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="w-[15%]">
            <PnlColumn
              activeTab={activeTab}
              socketLoading={socketLoading}
              alterSocketLoading={alterSocketLoading}
            />
          </div> */}

          <div className="hidden w-0 [57%] mt-2 text-[14px] text-center">
            <div className="flex justify-between text-tertiary font-bold h-10">
              <div className="pb-2 w-[17%]">Discord Info</div>
              <div className="pb-2 w-[15%]">Qty left/total</div>
              <div className="pb-2 w-[24%]">STC Qty</div>
              <div className="pb-2 w-[30%]">STC Alert</div>
              <div className="pb-2 w-[10%]"> </div>
            </div>
            <div className="flex flex-col gap-3">
              {data.map((item, index) => {
                const pnlValue = pnlData[item.id] || {};
                const message = `${name}:- STC ${qts[index] || 0} ${
                  item.ticker
                } ${item.strikePrice}${item?.contractType
                  ?.slice(0, 1)
                  .toUpperCase()} ${moment(
                  item.expirationDate,
                  "YYYY-MM-DD"
                ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;
                return (
                  <div
                    key={index}
                    className="flex gap-2 justify-between text-[#929292] h-16 w-full"
                  >
                    {/* Discord Info */}
                    <div className="py-1 text-center w-[17%] flex flex-col ">
                      {item.discord_server_name &&
                      item?.discord_channel_name ? (
                        <>
                          <span>{item?.discord_server_name}</span>{" "}
                          <span>{item?.discord_channel_name}</span>
                        </>
                      ) : (
                        "NA"
                      )}
                    </div>
                    {/* Qty left / total */}
                    <div className="py-1 pl-1 w-[15%] text-center">{`${
                      item.remainingQty || 0
                    }/${item.qty}`}</div>
                    {/* STC Qty */}
                    <div className="py-1 p- w-[24%] text-center">
                      <div className="flex justify-center gap-2">
                        <input
                          disabled={
                            activeTab == "expired" ||
                            !item.remainingQty ||
                            socketLoading
                          }
                          type="text"
                          value={qts[index]}
                          className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
                          onChange={(e) =>
                            setQts((prevData) => ({
                              ...prevData,
                              [index]: e.target.value,
                            }))
                          }
                          onBlur={(e) => {
                            const value = e.target.value
                              ? parseInt(e.target.value)
                              : "";
                            if (!value) return;

                            if (value > item.remainingQty) {
                              toast(`You don've have ${value} qty`);
                              setQts((prevData) => ({
                                ...prevData,
                                [index]: "",
                              }));
                            }
                          }}
                        />
                        <button
                          className={`bg-[#424242] text-tertiary px-2 py-1 rounded-md ${
                            !qts[index] ||
                            messageLoading[index] ||
                            !item.remainingQty
                              ? "cursor-not-allowed opacity-45"
                              : "cursor-pointer"
                          }`}
                          title={
                            !item.remainingQty
                              ? "No quantites are left"
                              : !qts[index]
                              ? "Please add sell quantity"
                              : messageLoading[index]
                              ? "Please wait for STC process"
                              : ""
                          }
                          onClick={() =>
                            qts[index] &&
                            activeTab != "expired" &&
                            handleSendMessage(item, index)
                          }
                        >
                          {messageLoading[index] ? (
                            <div className="flex items-center text-white gap-1">
                              Sending{" "}
                              <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                            </div>
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </div>
                    {/* STC Alert */}
                    <div className="py-1 p- w-[30%] text-center">
                      <span
                        type="text"
                        className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
                      >
                        {message}
                      </span>
                    </div>

                    {/* Actions  */}
                    {activeTab == "active" ? (
                      <div
                        className={`pb-2 pl-2 text-center w-[10%] p-2 text-white
                        ${socketLoading ? "pointer-events-none" : ""}  
                      `}
                      >
                        <Popconfirm
                          open={confirmOpens[index]}
                          title="Are you sure that you want to sell this one.?"
                          okButtonProps={{
                            type: "primary",
                            danger: true,
                            loading,
                            htmlType: "button",
                          }}
                          onCancel={() =>
                            setConfirmOpens((prevValues) => ({
                              ...prevValues,
                              [index]: false,
                            }))
                          }
                          onConfirm={() =>
                            socketLoading ? "" : handleSellFullBto(item, index)
                          }
                        >
                          <div
                            className="bg-red-500 text-white px-1 rounded-lg py-1 cursor-pointer"
                            title="Click to sell the full BTO's quantity"
                            onClick={() =>
                              setConfirmOpens((prevValues) => ({
                                ...prevValues,
                                [index]: true,
                              }))
                            }
                          >
                            {confirmOpens[index] ? "Selling" : "Sell All"}
                          </div>
                        </Popconfirm>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4 md:hidden text-[14px]">
        {data?.map((item, index) => {
          const pnlValue = pnlData[item.id] || {};
          const message = `${name}:- STC ${qts[index] || 0} ${item.ticker} ${
            item.strikePrice
          }${item?.contractType?.slice(0, 1).toUpperCase()} ${moment(
            item.expirationDate,
            "YYYY-MM-DD"
          ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;
          return (
            <div
              className="bg-[#202020] rounded-lg px-2 py-4 mt-4 text-white"
              key={index}
            >
              {activeTab !== "active" ? (
                <div className="w-full flex flex-col gap-2 5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <span className="font-bold">{item.ticker}</span>
                      <PnlColumn
                        activeTab={activeTab}
                        btoItem={item}
                        isMobile={true}
                        socketLoading={socketLoading}
                        alterSocketLoading={alterSocketLoading}
                      />
                    </div>
                    <span
                      onClick={() =>
                        setCollapsible((preValues) => ({
                          ...preValues,
                          [index]: !preValues[index],
                        }))
                      }
                    >
                      {collapsible[index] ? (
                        <RiArrowDropUpLine className="text-3xl" />
                      ) : (
                        <RiArrowDropDownLine className="text-3xl" />
                      )}
                    </span>
                  </div>

                  
                  <div className="w-full">
                    
                      <div 
                        className={`rounded-md px- 2 py- 1 font-semibold w- [52px] mx- auto px-2 [97px] text-center w-fit
                          ${item?.isPaperMoney ?  "bg-[#b2c1c1] text-black white" : "bg-[#00c896] text-black"}
                        `}
                      >
                        {item?.isPaperMoney ? "Paper" : "Real"}
                      </div>
                  </div>

                  {collapsible[index] ? (
                    <>
                      <div className="flex gap-2">
                        {/* <div className="flex gap-1 w-1/3">
                        <span className="text-tertiary">Ticker:</span>
                        <span>
                          {item?.ticker}
                        </span>
                      </div> */}
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Strike:</span>
                          <span>{item?.strikePrice}</span>
                        </div>
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Trade Duration:</span>
                          <span>{item?.trade_duration}</span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Entry:</span>
                          <span>{item?.quote}</span>
                        </div>

                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Exit:</span>
                          <span
                            className={`
                          ${
                            item?.selling_quote > item?.quote
                              ? "text-green-500"
                              : item?.selling_quote === item?.quote
                              ? "text-yellow-500"
                              : item?.selling_quote < item.quote &&
                                "text-red-500"
                          }
                        `}
                          >
                            {item?.selling_quote}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">STC History:</span>
                          <span>{item?.stc_array}</span>
                        </div>
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">
                            Qty:
                          </span>
                          <span>{item?.qty}</span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap-1 w- 1/2">
                          <span className="text-tertiary">
                            Expiration Date:
                          </span>
                          <span>{item?.expirationDate}</span>
                        </div>
                      </div>

                      {/* <div className="w-full flex gap-1">
                      <div className="w-[40%] flex gap-1">
                        <span className="text-tertiary">Qty Info:</span>
                        <span>
                          {item.remainingQty || 0}/{item.qty}
                        </span>
                      </div>
                      <div className="w-[60%] flex gap-1">
                        <span className="text-tertiary w-[40%]">STC Qty</span>
                        <div className="flex gap-1">
                          <input
                            disabled={
                              activeTab == "expired" ||
                              !item.remainingQty ||
                              socketLoading
                            }
                            type="text"
                            value={qts[index]}
                            className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
                            onChange={(e) =>
                              setQts((prevData) => ({
                                ...prevData,
                                [index]: e.target.value,
                              }))
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? parseInt(e.target.value)
                                : "";
                              if (!value) return;

                              if (value > item.remainingQty) {
                                toast(`You don've have ${value} qty`);
                                setQts((prevData) => ({
                                  ...prevData,
                                  [index]: "",
                                }));
                              }
                            }}
                          />
                          <button
                            className={`bg-[#424242] text-tertiary px-2 py-1 rounded-md ${
                              !qts[index] ||
                              messageLoading[index] ||
                              !item.remainingQty
                                ? "cursor-not-allowed opacity-45"
                                : "cursor-pointer"
                            }`}
                            title={
                              !item.remainingQty
                                ? "No quantites are left"
                                : !qts[index]
                                ? "Please add sell quantity"
                                : messageLoading[index]
                                ? "Please wait for STC process"
                                : ""
                            }
                            onClick={() =>
                              qts[index] &&
                              activeTab != "expired" &&
                              handleSendMessage(item, index)
                            }
                          >
                            {messageLoading[index] ? (
                              <div className="flex items-center text-white gap-1">
                                Sending{" "}
                                <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                              </div>
                            ) : (
                              "Send"
                            )}
                          </button>
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="flex gap-1">
                      <span className="text-tertiary">STC Alert</span>
                      <span
                        type="text"
                        className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
                      >
                        {message}
                      </span>
                    </div> */}
                      {/* <div
                      className={`w-full flex justify-end
                          ${socketLoading ? "pointer-events-none" : ""}  
                        `}
                    >
                      <Popconfirm
                        open={confirmOpens[index]}
                        title="Are you sure that you want to sell this one.?"
                        okButtonProps={{
                          type: "primary",
                          danger: true,
                          loading,
                          htmlType: "button",
                        }}
                        onCancel={() =>
                          setConfirmOpens({ ...confirmOpens, [index]: false })
                        }
                        onConfirm={() => handleSellFullBto(item, index)}
                      >
                        <Button
                          onClick={() =>
                            setConfirmOpens({
                              ...confirmOpens,
                              [index]: true,
                            })
                          }
                          type="primary"
                          className="bg-red-500 text-white"
                        >
                          {confirmOpens[index] ? "Selling" : "Sell All"}
                        </Button>
                      </Popconfirm>
                    </div> */}
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="w-full flex flex-col gap-2 5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <span className="font-bold">{item.ticker}</span>
                      <PnlColumn
                        activeTab={activeTab}
                        btoItem={item}
                        isMobile={true}
                        socketLoading={socketLoading}
                        alterSocketLoading={alterSocketLoading}
                      />
                    </div>
                    <span
                      onClick={() =>
                        setCollapsible((preValues) => ({
                          ...preValues,
                          [index]: !preValues[index],
                        }))
                      }
                    >
                      {collapsible[index] ? (
                        <RiArrowDropUpLine className="text-3xl" />
                      ) : (
                        <RiArrowDropDownLine className="text-3xl" />
                      )}
                    </span>
                  </div>

                  <div className="w-full">
                    
                      <div 
                        className={`rounded-md px- 2 py- 1 font-semibold w- [52px] mx- auto px-2 [97px] text-center w-fit
                          ${item?.isPaperMoney ?  "bg-[#b2c1c1] text-black white" : "bg-[#00c896] text-black"}
                        `}
                      >
                        {item?.isPaperMoney ? "Paper" : "Real"}
                      </div>
                  </div>

                  {collapsible[index] ? (
                    <>
                      <div className="flex gap-2">
                        {/* <div className="flex gap-1 w-1/3">
                        <span className="text-tertiary">Ticker:</span>
                        <span>
                          {item?.ticker}
                        </span>
                      </div> */}
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Strike:</span>
                          <span>{item?.strikePrice}</span>
                        </div>
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary">Entry:</span>
                          {item?.quote}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary w-fit">
                            Date Alerted:
                          </span>
                          <span>
                            {moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </span>
                        </div>
                        <div className="flex gap-1 w-1/2">
                          <span className="text-tertiary w-fit">
                            Expiration Date:
                          </span>
                          {item?.discord_server_name &&
                          item?.discord_channel_name ? (
                            <span>{item?.expirationDate}</span>
                          ) : (
                            "NA"
                          )}
                        </div>
                      </div>

                      <div className="flex gap-2"></div>

                      <div className="w-full flex gap-1">
                        <div className="w-[40%] flex gap-1">
                          <span className="text-tertiary">Qty Info:</span>
                          <span>
                            {item.remainingQty || 0}/{item.qty}
                          </span>
                        </div>
                        <div className="w-[60%] flex gap-1">
                          <span className="text-tertiary w-[40%]">STC Qty</span>
                          <div className="flex gap-1">
                            <input
                              disabled={
                                activeTab == "expired" ||
                                !item.remainingQty ||
                                socketLoading
                              }
                              type="text"
                              value={qts[index]}
                              className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
                              onChange={(e) =>
                                setQts((prevData) => ({
                                  ...prevData,
                                  [index]: e.target.value,
                                }))
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? parseInt(e.target.value)
                                  : "";
                                if (!value) return;

                                if (value > item.remainingQty) {
                                  toast(`You don've have ${value} qty`);
                                  setQts((prevData) => ({
                                    ...prevData,
                                    [index]: "",
                                  }));
                                }
                              }}
                            />
                            <button
                              className={`bg-[#424242] text-tertiary px-2 py-1 rounded-md ${
                                !qts[index] ||
                                messageLoading[index] ||
                                !item.remainingQty
                                  ? "cursor-not-allowed opacity-45"
                                  : "cursor-pointer"
                              }`}
                              title={
                                !item.remainingQty
                                  ? "No quantites are left"
                                  : !qts[index]
                                  ? "Please add sell quantity"
                                  : messageLoading[index]
                                  ? "Please wait for STC process"
                                  : ""
                              }
                              onClick={() =>
                                qts[index] &&
                                activeTab != "expired" &&
                                handleSendMessage(item, index)
                              }
                            >
                              {messageLoading[index] ? (
                                <div className="flex items-center text-white gap-1">
                                  Sending{" "}
                                  <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                                </div>
                              ) : (
                                "Send"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-1">
                        <span className="text-tertiary">STC Alert</span>
                        <span
                          type="text"
                          className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
                        >
                          {message}
                        </span>
                      </div>
                      <div
                        className={`w-full flex justify-end
                          ${socketLoading ? "pointer-events-none" : ""}  
                        `}
                      >
                        <Popconfirm
                          open={confirmOpens[index]}
                          title="Are you sure that you want to sell this one.?"
                          okButtonProps={{
                            type: "primary",
                            danger: true,
                            loading,
                            htmlType: "button",
                          }}
                          onCancel={() =>
                            setConfirmOpens({ ...confirmOpens, [index]: false })
                          }
                          onConfirm={() => handleSellFullBto(item, index)}
                        >
                          <Button
                            onClick={() =>
                              setConfirmOpens({
                                ...confirmOpens,
                                [index]: true,
                              })
                            }
                            type="primary"
                            className="bg-red-500 text-white"
                          >
                            {confirmOpens[index] ? "Selling" : "Sell All"}
                          </Button>
                        </Popconfirm>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  ) : (
    <Empty description={`No ${current} BTOs found`} />
  );
});

export default RenderTable;
