import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { userStore } from "../../../store/user";
import axios from "axios";

import tradier from "../../../assets/icons/dashboard/tradier.png";
import eTrade from "../../../assets/icons/dashboard/etrade.png";
import weBull from "../../../assets/icons/dashboard/webullLogo.png";
import polygon from "../../../assets/icons/dashboard/polygon.png";
import info from "../../../assets/icons/info.png";
import close from "../../../assets/icons/cross.png";

import getToken from "../../../assets/Images/Dashboard/getToken.png";
import auth from "../../../assets/Images/Dashboard/auth.png";
import permission from "../../../assets/Images/Dashboard/permission.png";
import code from "../../../assets/Images/Dashboard/code.png";
import enterToken from "../../../assets/Images/Dashboard/enterToken.png";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

import Loader from "../../../components/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ModelAuth = () => {
  
  const navigate = useNavigate()
  const location = useLocation()

  // TODO: Needs to come from server - oauth has implementation
  const scopes = "read, write, trade, market, stream";

  const [selectedService, setSelectedService] = useState("Etrade")
  const [isLoading, setIsLoading] = useState(false)

  const [eTradeDialog, setETradeDialog] = useState(false)
  const [eTradeLoading, setETradeLoading] = useState(false) 
  const [eTradeAccessToken, setETradeAccessToken] = useState("")

  const [weBullDialog, setWeBullDialog] = useState(false);
  const [weBullLoading, setWeBullLoading] = useState(false);
  const [instructionWeBull, setInstructionWeBull] = useState(false)
  const [weBullAccessToken, setWeBullAccessToken] = useState("");
  const [polygonDialog, setPolygonDialog] = useState("");
  const [polygonLoading, setPolygonLoading] = useState(false)

  const [authConfig, setAuthConfig] = useState({});

  const [instruction, setInstruction] = useState(false);

  const [bothServices, setBothServices] = useState(false)
    
  const {
    userData,
    btoData,
    hasDataBrokerTokens,//--
    setHasDataBrokerTokens, //--
    setHasBrokerTokens,
    setAuthBroker,
    dataAuthBroker, //--
    setDataAuthBrokerId,
    setDataAuthBroker, //--
    // savedConfig, 
    // setSavedConfig,
    savedDataConfig, 
    setSavedDataConfig,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    setBrokerBalance,
    setIsPaperTrade,
    brokerBalance,
    brokerList
  } = userStore((state) => state);

  const updateAuthConfig = (e, fieldName) => {
    setAuthConfig({
      ...authConfig,
      [fieldName]: e.target.value,
    });
  };

  
  
  const handleTradierAuth = async () => {
    const stateObject = {
      uuid: uuidv4(), // Unique identifier for the OAuth flow
      authType: "data", // Specify "data" or "wallet"
    };
    const stateData = btoa(JSON.stringify(stateObject)); // Encode state as a Base64 string
    localStorage.setItem('oauth-state-data', stateData);
    console.log(stateData);
    // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/state`, {
    //   "apiSource": "Tradier", // TODO: make dynamic
    //   "state": state,
    //   "userId": localStorage.getItem('userId')
    // });
    const callbackUrl = `${window.location.href}?type=data`;
    const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scope=${scopes}&state=${stateData}&redirect_uri=${callbackUrl}`;
    window.location.href = target;
  };

  
  const checkUserConfig = async (serviceName) => {
    if(savedDataConfig?.[serviceName] !== undefined){
      return;
    }

    setIsLoading(true);
    
    console.log("serviceName", serviceName, savedDataConfig?.[serviceName])

    if (savedDataConfig?.[serviceName] == undefined) {
      await axios
        .get(
          `https://backend-ums-stage-797b8d335ec5.herokuapp.com/broker/userConfig/${localStorage.getItem(
            "userId"
          )}/${brokerList[serviceName]}?brokerType=Data`
          // , {
          //   brokerType: "Wallet"
          // }
        )
        .then((response) => {
          console.log(response, "auth success");
          setSavedDataConfig({ ...savedDataConfig, [serviceName]: true });
        })
        .catch((error) => {
          console.log(error);
          setSavedDataConfig({ ...savedDataConfig, [serviceName]: false });
        });
    }
    setIsLoading(false);
  };

  console.log("savedDataConfig", savedDataConfig)

  const BrokerLogin = async (brokerName, brokerType, setAllBrokerTypes = false, config = {}) => {

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/login`, {
      "userId": localStorage.getItem('userId'),
      "brokerName": brokerName, //"Webull", "Tradier"
      "brokerType": brokerType, //Data, Wallet
      "setAllBrokerTypes": false, // Set it to true if you want to set both data and wallet bropker types with same value .
      "credentials": {
        ...config
      }
    })
      .then(async (res) => {
        if (res.status === 200) {

          if(brokerName !== "Etrade"){
            toast("Successfully authenticated", { type: "success" });
            setHasDataBrokerTokens(true);
            setDataAuthBroker(brokerName);
            setDataAuthBrokerId(brokerList[brokerName]);
            setAuthConfig({});
          }
          
          switch(brokerName){
            case "Tradier":
              navigate('/dashboard')
              break;
            case "Etrade":
              setIsLoading(false)
              window.open(res.data?.redirectUrl, "_blank")
              break;
            case "Polygon":
              setPolygonDialog(false)
              setPolygonLoading(false);
              break;
            default:
              setWeBullDialog(false)
              setWeBullLoading(false);
              break;
          }
        } else {
          toast("Authentication Failed", { type: "error" });
          setPolygonDialog(false)
          setPolygonLoading(false)
          setWeBullDialog(false)
          setWeBullLoading(false)
          setIsLoading(false)
          navigate('/dashboard')
        }
      })
      .catch((error) => {
        console.log(error);

        setPolygonDialog(false)
        setPolygonLoading(false)
        setWeBullDialog(false)
        setWeBullLoading(false)
        setIsLoading(false)
        navigate('/dashboard')
        
        if(error.response.status === 417){
          return toast.error("Invalid Security DID")
        }
        toast.error("Something went wrong. Please try again later")
        // setWeBullLoading(false);
      })
      .finally(() => {
        setPolygonLoading(false)
      })
  }

  const handleRequestToken = async (e) => {
    setIsLoading(true)
    let config;

    if(!savedDataConfig?.Etrade) {
      if(!authConfig?.consumerKey){
        setIsLoading(false)
        return toast.error("Enter consumer key")
      } else if(!authConfig?.consumerKey){
        setIsLoading(false)
        return toast.error("Enter consumer sercret")
      }
      config = {
        consumerKey: authConfig?.consumerKey,
        consumerSecret: authConfig?.consumerSecret
      }
    }

    await BrokerLogin("Etrade", "Data", false, config)
    
    // await axios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`, {
    //   params: {
    //     userId: localStorage.getItem("userId"),
    //     ...config
    //   }
    // })
    // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/login`, {
    //   "userId": localStorage.getItem('userId'),
    //   "brokerName": "Etrade", //"Webull", "Tradier"
    //   "brokerType":"Data", //Data, Wallet
    //   "setAllBrokerTypes": false, // Set it to true if you want to set both data and wallet bropker types with same value .
    //   "credentials": {
    //     ...config
    //   }
    // })
    //   .then((res) => {
    //     console.log(res.data)
    //     window.open(res.data?.redirectUrl, "_blank")
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     if(error.respon.status === 417){
    //       return toast.error("Invalid Credentials")
    //     } else {
    //       return toast.error("Something went wrong. Please try again later.")
    //     }
    //   })
    //   .finally(() => {
    //     setIsLoading(false)
    //   })
  }

  const handleEtradeAuth = async (e) => {
    if(eTradeAccessToken.length == 0){
      return toast.error("Enter Access Token!")
    }
    setETradeLoading(true)
    
    await axios
      .post(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/oauth/etrade/callback`, {
          "code": eTradeAccessToken, 
          "userId": localStorage.getItem("userId" ), 
          "brokerType": "Data", 
          "setAllBrokerTypes" : false
        }
      )
      .then(async (res) => {
        if(res.status === 200) {
          if(bothServices){
            setHasDataBrokerTokens(true); 
            setAuthBroker("Etrade");

            await axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` +
                localStorage.getItem("userId")
            )
            .then((res) => {
              console.log(res.data);
              setAccountList(res.data.accountList);
              setSelectedAccount(res.data.accountList[0]?.accountIdKey);
            })
            .catch((err) => console.error(err));
          }
        
          setHasDataBrokerTokens(true)
          toast("Successfully authenticated", { type: "success" });
          setDataAuthBroker("Etrade")
          setETradeDialog(false)
          setETradeLoading(false)
          setAuthConfig({});

          // await axios
          //   .get(`${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` + localStorage.getItem('userId'))
          //     .then(res => {
          //       console.log(res.data)
          //       setAccountList(res.data.accountList)    
          //       setSelectedAccount(res.data.accountList[0]?.accountIdKey);
          //     })
          //     .catch(err => console.error(err));
        } else {
          toast("Authentication Failed", { type: "error" });
        }
      })
      .catch((error) => {
        console.log(error)
        setETradeLoading(false)
        if(error.response.status === 401){
          return toast.error("Invalid Access Token")
        } else {
          return toast.error("Something went wrong. Please try again later")
        }
      })

    // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;
    
    // window.open(target, "_blank");
  };

  const handlePolygonAuth = async (e) => {
    let config;
    if(!savedDataConfig?.Polygon){

      if(!authConfig?.apiKey || authConfig?.apiKey.length == 0){
        return toast.error("Enter API Key!")
      }
    }
    
    config = {
      apiKey: authConfig?.apiKey
    }
    setPolygonLoading(true)

    await BrokerLogin("Polygon", "Data", false, config)
    
    // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/login`, {
    //   "userId": localStorage.getItem('userId'),
    //   "brokerName": "Polygon", //"Webull", "Tradier"
    //   "brokerType":"Data", //Data, Wallet
    //   "setAllBrokerTypes": false, // Set it to true if you want to set both data and wallet bropker types with same value .
    //   "credentials": {
    //     ...config
    //   }
    // })
    //   .then(async (res) => {
    //     if (res.status === 200) {
    //       setHasDataBrokerTokens(true);
    //       setDataAuthBroker("Polygon");
    //       toast("Successfully authenticated", { type: "success" });
    //       setPolygonDialog(false);
    //       setAuthConfig({});
    //       setPolygonLoading(false);
    //     } else {
    //       toast("Authentication Failed", { type: "error" });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
        
    //     if(error.response.status === 417){
    //       return toast.error("Invalid Security DID")
    //     }
    //     toast.error("Something went wrong. Please try again later")
    //     // setWeBullLoading(false);
    //   })
    //   .finally(() => {
    //     setPolygonLoading(false)
    //   })
  };

  const handleWeBullAuth = async (e) => {
    let config; 

    if(savedDataConfig?.Webull){
      config = {
        securityDid: authConfig?.security,
      };
    } else {
      config = {
        loginEmail: authConfig?.email,
        loginPassword: authConfig?.password,
        tradingPin: authConfig?.pin,
        securityDid: authConfig?.security,
      };
    }

    if (
      !authConfig?.weBull &&
      (config?.loginEmail?.length === 0 ||
        config?.loginPassword?.length === 0 ||
        config?.tradingPin?.length === 0 ||
        config?.securityDid?.length === 0)
    ) {
      return toast.error("Enter all the details to proceed!");
    } else if (config?.securityDid?.length === 0) {
      return toast.error("Enter Security DID to proceed!");
    }

    setWeBullLoading(true);

    await BrokerLogin("Webull", "Data", false, config)

    // await axios
    //   .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/webull/login`, {
    //     userId: localStorage.getItem("userId"),
    //     ...config,
    //   })
    //   .then(async (res) => {
    //     if (res.status === 200) {
    //       setHasDataBrokerTokens(true);
    //       setDataAuthBroker("WeBull");
    //       toast("Successfully authenticated", { type: "success" });
    //       setWeBullDialog(false);
    //       setAuthConfig({});
    //       setWeBullLoading(false);
    //       setSavedDataConfig({...savedDataConfig, weBull: true})
    //     } else {
    //       toast("Authentication Failed", { type: "error" });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
        
    //     if(error.response.status === 417){
    //       return toast.error("Invalid Security DID")
    //     }
    //     toast.error("Something went wrong. Please try again later")
    //     setWeBullLoading(false);
    //   });

    // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;

    // window.open(target, "_blank");
  };

  
  useEffect(() => {
    const sendAuthCode = async (code) => {
      state = localStorage.getItem('oauth-state-data');
      let config = {
        code
      }
      
      await BrokerLogin("Tradier", "Data", false, config)

      // await axios
      //   .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/authcode`, {
      //     "user_id": localStorage.getItem('userId'),
      //     "api_source": "Tradier", // TODO: Needs to become dynamic
      //     "code": code,
      //     "state": state,
      //     "scope": scopes
      //   })
      
      // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/login`, {
      //   "userId": localStorage.getItem('userId'),
      //   "brokerName": "Tradier", //"Etrade", "Webull"
      //   "brokerType":"Data", //Data, Wallet
      //   "setAllBrokerTypes": false, // Set it to true if you want to set both data and wallet bropker types with same value .
      //   "credentials": {
      //     "code": code,
      //   }
      // })
      //   .then(async (res) => {
      //     if(res.status === 200) {
      //       toast("Successfully authenticated", { type: "success" });
      //       setHasDataBrokerTokens(true);
      //       setDataAuthBroker("Tradier")
      //       navigate('/dashboard')
      //     } else {
      //       toast("Authentication Failed", { type: "error" });
      //     }
      //   })
      //   .catch(err => console.error(err));
    };

    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    let state = query.get("state");  

    if (code && state) {
      const decodedState = JSON.parse(atob(state)); // Decode the Base64 state
      const { authType } = decodedState; // Extract the authType
  
      if (authType === "data") { // Change to "wallet" for wallet auth component
        sendAuthCode(code, authType);
        // localStorage.removeItem('oauth-state-data');
      }
    }
  }, [location]);

  
  const handleBrokerLogout = async () => {
    setIsLoading(true)
    
    await axios.delete(
      "https://backend-ums-stage-797b8d335ec5.herokuapp.com/oauth/token/", {
        params: {
          userId: localStorage.getItem("userId"),
          brokerName: dataAuthBroker,
          brokerType: "Data"
        }
      }
    )
    .then((response) => {
      console.log(response.data)
      setHasDataBrokerTokens(false)
      setDataAuthBroker(null)
      setSelectedAccount(null)
      setIsPaperTrade(true);
      setIsLoading(false)
    })
    .catch((error) => {
      console.log(error.data)
      setIsLoading(false)
    })
  }

  return (
    <>
    {(eTradeDialog || instruction || weBullDialog || polygonDialog) && (
        <div
          onClick={() => {setETradeDialog(false)
            setInstruction(false)
          }}
          className={`fixed h-screen w-full bg-black bg-opacity-60 ${instruction ? "z-[45]" : "z-30"}  top-0 left-0
            ${(eTradeLoading || polygonLoading) ? "pointer-events-none" : ""}
          `}
        />
      )}

      {eTradeDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            ETrade Login
            <img src={info} onClick={() => setInstruction(true)} className="size-5 cursor-pointer"/>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center min-h-[550px]">
              <Loader />
            </div>
          ) : (
            <>
              <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
              <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div>

              {!savedDataConfig?.Etrade && (
                <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                  <div className="text-white text-semibold text-center underline underline-offset-4">
                    Set One Time Config for ETrade
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Consumer Key:</label>
                    <input
                      type="text"
                      value={authConfig?.consumerKey || ""}
                      onChange={(e) => updateAuthConfig(e, "consumerKey")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Consumer Secret:</label>
                    <input
                      type="text"
                      value={authConfig?.consumerSecret || ""}
                      onChange={(e) => updateAuthConfig(e, "consumerSecret")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="text-tertiary mt-4 text-sm">
                    *The above details will be stored for future use. You can
                    change them fron the profile page{" "}
                  </div>
                  
                </div>
              )}

              <div className="w-full flex justify-center mt-6">
                <button
                  className="border-2 border-tertiary border-opacity- 20 rounded-md mx-auto px-4 py-2 text-white font-semibold text-center flex items-center gap-2"
                  onClick={() => handleRequestToken()}
                >
                  <img src={eTrade} className="size-6"/>
                  Get Access Token
                </button> 
              </div>
              
              <div className="w-full h-[1px] mt-6 bg-white opacity-15" />
              <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                <div className="flex items-center gap-2">
                  <label className="w-36">Enter Access Token:</label>
                  <input value={eTradeAccessToken} onChange={(e) => setETradeAccessToken(e.target.value)} className="bg-[#424242] px-2 py-1 rounded-md"/>
                </div>
                {/* <div className="flex gap-2 ml- 1">
                  <input
                    type="checkbox"
                    id="some_id"
                    onChange={() => setBothServices(!bothServices)}
                    checked={bothServices}
                    className="
                      relative peer shrink-0
                      appearance-none w-4 h-4 border-2 border-none rounded-sm bg-[#b2b2b2]
                      mt-1
                      checked:bg-tertiary checked:border-0
                    "
                  />
                  <label htmlFor="some_id" className="text-white text-opacity-80 [#7D7D7D]">
                    Set
                    <span className="underline underline-offset-4 ml-1 hover: text-tertiary">
                      ETrade
                    </span>
                    {" "}as the Order Placement {"("}Wallet{")"} account
                  </label>
                  <svg
                    className="
                      absolute 
                      w-4 h-4 mt-1
                      hidden peer-checked:block
                      pointer-events-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div> */}
                <div className="text-red-600 text-sm">
                  NOTE: Logging in will automatically log you out of other broker services
                </div>
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  disabled={eTradeLoading}
                  onClick={() => setETradeDialog(false)}
                  className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
                >
                  Cancel
                </button>
                <button
                  disabled={eTradeLoading}
                  onClick={handleEtradeAuth}
                  className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {instruction && (
        <div className="w-[60%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[46] text-[#9b9b9b] bg-[#191919] rounded-lg py-6 px-6 h-[750px]">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            How to Login to ETrade?
            <img src={close} onClick={() => setInstruction(false)} className="size-5 cursor-pointer"/>
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

          <div className="overflow-y-scroll scrollbar h-[650px] mt-4">

            <div className="">
              <div className="text-xl font-semibold">Step 1</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-4">Select the "Get Access Token" option and head over to ETrade.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 2</div>
              <img src={auth} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-4">Log in to ETrades with the required account and once logged in, close this tab and head back to Alertisfy.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 3</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Select the "Get Access Token" option again.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 4</div>
              <img src={permission} className="mt-4 h-64 mx-auto" /> 
              <img src={code} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-2">Accept the platform conditions, copy the code which comes after and head back over to Alertsify.</div>
            </div>
            
            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 5</div>
              <img src={enterToken} className="mt-4 h-36 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Enter the access token and submit auth request.</div>
            </div>
          </div>
        </div>
      )}

      {instructionWeBull && (
        <div className="w-[60%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[46] text-[#9b9b9b] bg-[#191919] rounded-lg py-6 px-6 h-[750px]">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            How to Login to ETrade?
            <img src={close} onClick={() => setInstruction(false)} className="size-5 cursor-pointer"/>
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

          <div className="overflow-y-scroll scrollbar h-[650px] mt-4">

            <div className="">
              <div className="text-xl font-semibold">Step 1</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-4">Select the "Get Access Token" option and head over to ETrade.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 2</div>
              <img src={auth} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-4">Log in to ETrades with the required account and once logged in, close this tab and head back to Alertisfy.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 3</div>
              <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Select the "Get Access Token" option again.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 4</div>
              <img src={permission} className="mt-4 h-64 mx-auto" /> 
              <img src={code} className="mt-4 h-64 mx-auto" /> 
              <div className="mt-2">Accept the platform conditions, copy the code which comes after and head back over to Alertsify.</div>
            </div>
            
            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 5</div>
              <img src={enterToken} className="mt-4 h-36 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">Enter the access token and submit auth request.</div>
            </div>
          </div>
        </div>
      )}        
      
      {weBullDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            <div className="flex gap-2 items-center">
              {/* <img src={weBull} className="size-8" /> */}
              WeBull Login
            </div>
            <img
              src={info}
              onClick={() => setInstructionWeBull(true)}
              className="size-5 cursor-pointer"
            />
          </div>

          <div className="w-full h-[1px] mt-6 bg-white opacity-15" />

          {isLoading ? (
            <div className="flex items-center justify-center min-h-[435px]">
              <Loader />
            </div>
          ) : (
            <>
              {!savedDataConfig?.Webull && (
                <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                  <div className="text-white text-semibold text-center underline underline-offset-4">
                    Set One Time Config for WeBull
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Email:</label>
                    <input
                      type="email"
                      value={authConfig?.email || ""}
                      onChange={(e) => updateAuthConfig(e, "email")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Password:</label>
                    <input
                      type="password"
                      value={authConfig?.password || ""}
                      onChange={(e) => updateAuthConfig(e, "password")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Trading Pin:</label>
                    <input
                      value={authConfig?.pin || ""}
                      onChange={(e) => updateAuthConfig(e, "pin")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="text-tertiary mt-4 text-sm">
                    *The above details will be stored for future use. You can
                    change them fron the profile page{" "}
                  </div>
                  
                  <div className="w-full h-[1px] mt-2 bg-white opacity-15 " />
                </div>
              )}

              <div className="flex items-center gap-2 mt-6 text-white">
                <label className="w-24">Security DID:</label>
                <input
                  value={authConfig?.security || ""}
                  onChange={(e) => updateAuthConfig(e, "security")}
                  className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                />
              </div>

              <div className="text-red-600 text-sm mt-4">
                NOTE: Logging in will automatically log you out of other
                broker services
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  disabled={eTradeLoading}
                  onClick={() => {
                    setWeBullDialog(false);
                    setAuthConfig({});
                  }}
                  className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
                >
                  Cancel
                </button>
                <button
                  disabled={weBullLoading}
                  onClick={handleWeBullAuth}
                  className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {polygonDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            Polygon Login
            {/* <img src={info} onClick={() => setInstruction(true)} className="size-5 cursor-pointer"/> */}
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center min-h-[550px]">
              <Loader />
            </div>
          ) : (
            <>
              {/* <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
              <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div>
               */}
              <div className="w-full h-[1px] mt-6 bg-white opacity-15" />
              {!savedDataConfig?.Polygon && (
                <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                  <div className="flex items-center gap-2">
                    <label className="w-36">Enter API Key:</label>
                    <input
                        type="text"
                        value={authConfig?.apiKey || ""}
                        onChange={(e) => updateAuthConfig(e, "apiKey")}
                        className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                      />
                  </div>
                  
                </div>
              )}
              <div className="text-white text-lg mt-4">
                Are you sure you want to login to Polygon?{" "}
              </div>
              <div className="text-red-600 text-sm mt-4">
                NOTE: Logging in will automatically log you out of other broker services
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  disabled={polygonLoading}
                  onClick={() => setPolygonDialog(false)}
                  className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
                >
                  Cancel
                </button>
                <button
                  disabled={polygonLoading}
                  onClick={handlePolygonAuth}
                  className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      )}      

      {hasDataBrokerTokens ? (
        <div className="relative bg-[#202020] p-6 rounded-[32px] md:h-1/2 border border-[#6c6c6c]">
          <div className="flex gap-2 justify-between items-center text-[#A1A1A1] w-full">
            <div className="text-opacity-20 text-xl ">Model Data</div>
            <div className="flex items-center h-[23px]">
              <div className="">{dataAuthBroker ? dataAuthBroker : ""}:</div>
              <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#28A263]" />
              <div className="text-[#28A263]">Connected</div>
            </div>
          </div>
          <div className="text-lg absolute top-1/3 left-1/2 tasnform -translate-x-1/2 -translate-y-1/2 text-[#A1A1A1] mt-10 text-center">
            Head over to the {" "}
            <Link to={"/model"} className="text-tertiary hover:underline underline-offset-4 ">model</Link> 
            {" "}page to view live data when the market is open
          </div>
          {/* <div className="mt-10 flex items-center justify-between">
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.totalEquity !== undefined ? (
                  "$" +  Number(brokerBalance?.totalEquity)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Total Value</div>
            </div>
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.availableFunds !== undefined ? (
                  "$" + Number(brokerBalance?.availableFunds)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Available Funds</div>
            </div>
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.unsettledFunds !== undefined ? (
                  "$" + Number(brokerBalance?.unsettledFunds)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Unsettled Funds</div>
            </div>
          </div> */}
          <div className="absolute bottom-4 right-4 flex justify-end mt-4 md:mt-6">
            <button
              disabled={isLoading}
              className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg"
              onClick={handleBrokerLogout}
            >
              Logout
            </button>
          </div>
        </div>
      ) : (
        <div className="flex gap-2 bg-[#202020] p-6 rounded-[32px] h-1/2">
          <div className="w-fit h-full scroll-y-auto scrollbar">
            <img
              src={eTrade}
              onClick={() => setSelectedService("Etrade")}
              className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${
                selectedService === "Etrade"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            />
            <div className="w-full h-[2px] px-2 my-1 rounded-full bg-[#424242]" />
            <img
              src={tradier}
              onClick={() => setSelectedService("Tradier")}
              className={`size-10 p-1 rounded-lg cursor-pointer ${
                selectedService === "Tradier"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            />
            
            <div className="w-full h-[2px] px-2 my-1 rounded-full bg-[#424242]" />
            <img
              src={polygon}
              onClick={() => setSelectedService("Polygon")}
              className={`size-10 p-1 rounded-lg cursor-pointer ${
                selectedService === "Polygon"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            />
          </div>
          <div className="w-[2px] bg-[#424242] h-full" />
          <div className="w-full">
            <div className="flex justify-between items-center">
              <div className="text-[#A1A1A1] font-semibold text-xl ml-2">Model Data</div>
              <div className="flex items-center h-[23px]">
                <div className="text-[#A1A1A1]">Brokerage Account :</div>
                <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                <div className="text-[#F24E1E]">Not Connected</div>
              </div>  
            </div>
            
            <div className="flex flex-grow h-full justify-center items-center pt-5 pb-6">
              {(() => {
                switch (selectedService) {
                  case "Tradier":
                    return (
                      <>
                        {/* Tradier Auth */}
                        <div className="flex items-center justify-center gap-2 border border-white w-[200px] [#6E6E6E] rounded-lg py-2 px-3">
                          <img src={tradier} className="size-6" />
                          <button
                            onClick={handleTradierAuth}
                            className="text-tertiary"
                          >
                            Sign In with Tradier
                          </button>
                        </div>
                      </>
                    );
                  case "Etrade":
                    return (
                      <>
                        {/* eTrade Auth */}
                        <div className="flex items-center justify-center gap-2 border border-white w-[200px] [#6E6E6E] rounded-lg py-2 px-3">
                          <img src={eTrade} className="size-6" />
                          <button
                            onClick={() => {
                              checkUserConfig("Etrade");
                              setETradeDialog(true);
                              setAuthConfig({});
                            }}
                            className="text-tertiary"
                          >
                            Sign In with ETrade
                          </button>
                        </div>
                      </>
                    );
                  
                  case "Polygon":
                    return (
                      <>
                        {/* eTrade Auth */}
                        <div className="flex items-center justify-center gap-2 border border-white w-[200px] [#6E6E6E] rounded-lg py-2 px-3">
                          <img src={polygon} className="size-6 rounded-md" />
                          <button
                            onClick={() => {
                              checkUserConfig("Polygon");
                              setPolygonDialog(true);
                              setAuthConfig({});
                            }}
                            className="text-tertiary"
                          >
                            Sign In with Polygon
                          </button>
                        </div>
                      </>
                    );

                  default:
                    return (
                      <>
                        {/* weBull Auth */}
                        <div className="flex items-center justify-center gap-2 border border-white w-[200px] [#6E6E6E] rounded-lg py-2 px-3">
                          <img src={weBull} className="size-6" />
                          <button
                            onClick={() => {
                              checkUserConfig("Webull");
                              // setWeBullDialog(true);
                              setAuthConfig({});
                            }}
                            className="text-tertiary"
                          >
                            Sign In with WeBull
                          </button>
                        </div>
                      </>
                    );
                }
              })()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModelAuth;
