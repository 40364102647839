import React, { useState } from "react";

import bgLeft from "../../../assets/bgLeftRectangle.png";
import profile from "../../../assets/defaultPicture.png";
import phoneProfile from "../../../assets/defaultPhonePicture.png";
import tradier from "../../../assets/icons/dashboard/tradier.png";
import eTrade from "../../../assets/icons/dashboard/etrade.png";
import weBull from "../../../assets/icons/dashboard/webullLogo.png";
import polygon from "../../../assets/icons/dashboard/polygon.png";
import key from "../../../assets/icons/profile/key.png";
import secret from "../../../assets/icons/profile/secret.png";

import Sidebar from "../../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { MdOutlineMail, MdPerson } from "react-icons/md";
import { userStore } from "../../../store/user";
import { RiEditLine } from "react-icons/ri";
import Loader from "../../../components/Loader";
import DashHeader from "../../../components/DashHeader/DashHeader";
import axios from "axios";
import { toast } from "react-toastify";

const Profile = () => {
  const navigate = useNavigate();
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedService, setSelectedService] = useState("eTrade");
  const [config, setConfig] = useState({});
  const [configDialogEtrade, setConfigDialogEtrade] = useState(false);
  const [configDialogWeBull, setConfigDialogWeBull] = useState(false);
  const [configDialogPolygon, setConfigDialogPolygon] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");

  const {
    userData,
    btoData,
    hasBrokerTokens,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    setBrokerBalance,
    setIsPaperTrade,
    brokerBalance,
    setHasBrokerTokens,
    setAuthBroker,
    setHasDataBrokerTokens,
    setDataAuthBroker
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    email,
    username,
    currentBalance,
    investedAmount,
    createdAt,
  } = userData || {};
  
  const [serviceType, setServiceType] = useState("default");

  const updateAuthConfig = (e, fieldName) => {
    setConfig({
      ...config,
      [fieldName]: e.target.value,
    });
  };

  const handleConfigSubmit = async (e) => {
    let selectedConfig;
    let brokerId;
    if (!serviceType || serviceType == "default"){
      return toast.error("Please select broker service type")
    }
    if (selectedService == "eTrade") {
      if (!config?.consumerKey || config?.consumerKey?.length == 0) {
        return toast.error("Enter Consumer Key!");
      } else if (
        !config?.consumerSecret ||
        config?.consumerSecret?.length == 0
      ) {
        return toast.error("Enter Consumer Key!");
      }

      selectedConfig = {
        consumerKey: config?.consumerKey,
        consumerSecret: config?.consumerSecret,
      };
      brokerId="ab5ccb68-310c-4475-9631-97bfe4e1448e"
    } else if (selectedService == "polygon") {
      if(!config?.apiKey || config?.apiKey?.length == 0) {
        return toast.error("Enter API Key!");
      }
      selectedConfig = {
        apiKey: config?.apiKey
      }
      brokerId="33780bcc-3661-4344-9e61-fdbfd987c9b5"
    } else {
      if (!config?.loginEmail || config?.loginEmail?.length == 0) {
        return toast.error("Enter Email!");
      } else if (!config?.loginPassword || config?.loginPassword?.length == 0) {
        return toast.error("Enter Password!");
      } else if (!config?.tradingPin || config?.tradingPin?.length == 0) {
        return toast.error("Enter Trading Pin!");
      } else if (!config?.securityDid || config?.securityDid?.length == 0) {
        return toast.error("Enter Security DID!");
      }
      selectedConfig = {
        loginEmail: config?.loginEmail,
        loginPassword: config?.loginPassword,
        tradingPin: config?.tradingPin,
        securityDid: config?.securityDid,
      };
      brokerId="00acd39f-c7f5-402e-92fd-a62175e6d696"
    }

    setConfigLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/broker/userConfig`, {
        userId: localStorage.getItem("userId"),
        brokerId: brokerId,
        // config: {
        //   consumerKey: config?.consumerKey,
        //   consumerSecret: config?.consumerSecret,
        // },
        config: selectedConfig,
        brokerType: serviceType
      })
      .then(async (res) => {
        toast.success("Config updated. Login to continue.");
        if(serviceType == "Data"){
          setHasDataBrokerTokens(false)
          setDataAuthBroker(null)
          // setSelectedAccount(null)
        } else if(selectedService == "Wallet"){
          setHasBrokerTokens(false);
          setAuthBroker(null);
          setAccountList(null);
          setSelectedAccount(null);
          setBrokerBalance(null);
        }
        setIsPaperTrade(true);

        setConfig({});
        setServiceType("default")
        setConfigDialogEtrade(false);
        setConfigDialogWeBull(false);
        setConfigDialogPolygon(false);
        setConfigLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again later!");
        console.log(error);
        setConfigLoading(false);
      });

    // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;

    // window.open(target, "_blank");
  };

  return (
    <div className="lg:h-screen flex flex-col lg:flex-row bg bg-cover bg-center">
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"profile"}
      />

      {(configDialogEtrade || configDialogWeBull || configDialogPolygon) && (
        <div
          onClick={() => {
            setConfigDialogEtrade(false);
            setConfigDialogWeBull(false);
            setConfigDialogPolygon(false);
            setServiceType("default")
            setConfig({});
          }}
          className={`fixed h-screen w-full bg-black bg-opacity-60 z-30 top-0 left-0
            ${configLoading ? "pointer-events-none" : ""}
          `}
        />
      )}

      {configDialogEtrade && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            Set Config
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
          {/* <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div> */}

          {/* <div className="w-full h-[1px] mt-6 bg-white opacity-15" /> */}
          <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Service Type:</label>
              <select
                value={serviceType}
                onChange={(e) => setServiceType(e.target.value)}
                className="bg-[#424242] px-2 py-1 rounded-md"
              >
                <option value="default" disabled className="text-gray-500">
                </option>
                <option value="Data">Data</option>
                {/* <option value="wallet">Wallet</option> */}
              </select>
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Consumer Key:</label>
              <input
                value={config?.consumerKey}
                onChange={(e) => updateAuthConfig(e, "consumerKey")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Consumer Secret:</label>
              <input
                value={config?.consumerSecret}
                onChange={(e) => updateAuthConfig(e, "consumerSecret")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={configLoading}
              onClick={() => {
                setConfigDialogEtrade(false);
                setConfig({});
                setServiceType("default")
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={configLoading}
              onClick={handleConfigSubmit}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {configDialogWeBull && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            Set Config
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
          {/* <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div> */}

          {/* <div className="w-full h-[1px] mt-6 bg-white opacity-15" /> */}
          <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Service Type:</label>
              <select
                value={serviceType}
                onChange={(e) => setServiceType(e.target.value)}
                className="bg-[#424242] px-2 py-1 rounded-md"
              >
                <option value="default" disabled className="text-gray-500">
                </option>
                {/* <option value="data">Data</option> */}
                <option value="Wallet">Wallet</option>
              </select>
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Email:</label>
              <input
                value={config?.loginEmail}
                onChange={(e) => updateAuthConfig(e, "loginEmail")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Password:</label>
              <input
                value={config?.loginPassword}
                onChange={(e) => updateAuthConfig(e, "loginPassword")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Trading Pin:</label>
              <input
                value={config?.tradingPin}
                onChange={(e) => updateAuthConfig(e, "tradingPin")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">Security DID:</label>
              <input
                value={config?.securityDid}
                onChange={(e) => updateAuthConfig(e, "securityDid")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={configLoading}
              onClick={() => {
                setConfigDialogWeBull(false);
                setConfig({});
                setServiceType("default")
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={configLoading}
              onClick={handleConfigSubmit}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      
      {configDialogPolygon && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            Set Config
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
          {/* <div className="text-white mt-4 text-center">Login on the ETrade platform to obtain the access token </div> */}

          {/* <div className="w-full h-[1px] mt-6 bg-white opacity-15" /> */}
          <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Service Type:</label>
              <select
                value={serviceType}
                onChange={(e) => setServiceType(e.target.value)}
                className="bg-[#424242] px-2 py-1 rounded-md"
              >
                <option value="default" disabled className="text-gray-500">
                </option>
                <option value="Data">Data</option>
                {/* <option value="wallet">Wallet</option> */}
              </select>
            </div>

            <div className="flex items-center gap-2">
              <label className="w-36">API Key:</label>
              <input
                value={config?.apiKey}
                onChange={(e) => updateAuthConfig(e, "apiKey")}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>

          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={configLoading}
              onClick={() => {
                setConfigDialogPolygon(false);
                setConfig({});
                setServiceType("default")
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={configLoading}
              onClick={handleConfigSubmit}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {/* {hasBrokerTokens !== null && !isLoading ? ( */}
      <div
        className={`hid den lg:block ${
          bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
        } bg-cover bg-no-repeat bg-left px-4 lg:px-12 py-8 text-[15px] overflow-y-scroll scrollbar`}
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        {/* Top bar only for bigger screens  */}

        <DashHeader />

        <div
          className="hidden lg:block lg:w- [80%] bg-cover bg-no-repeat bg-left text-[16px]"
          style={{ backgroundImage: `url(${bgLeft})` }}
        >
          <div>
            <div className="text-[42px] text-[#C5C5C5] font-semibold mt-4">
              My Profile
            </div>

            <div
              className="
                flex bg-[#202020] rounded-3xl mt-8 py-10 border border-[#353535]  drop-shadow-2xl 
                px-12
                w-full
                "
            >
              <div className="flex w-[166px]">
                <img
                  src={avatarUrl || profile}
                  alt="profile"
                  className="size-[130px] rounded-full"
                />
              </div>
              <div className="w-full pl-4 flex flex-col gap-3 text-[#9B9B9B] ">
                <div className="flex gap-2 justify-between items-center">
                  <div className="w-1/2">
                    <div className="text-sm font-semibold">Username</div>
                    <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                      <MdPerson size={25} />
                      <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                        {name}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="text-sm font-semibold">Email</div>
                    <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                      <MdOutlineMail size={25} />
                      <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                        {email}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-4">
                  <div className="font-semibold  ">Discord Username :</div>
                  <div className="bg-[#2D2D2D] text-white px-3 py-[2px] rounded-lg">
                    {username}
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-2">
                  <div className="font-semibold ">User Since :</div>
                  <div className="bg-[#2D2D2D] text-white px-3 py-[2px] rounded-lg">
                    {new Date(createdAt).toLocaleString("en-US", {
                      timeZone: "America/New_York",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-2">
                  <div className="font-semibold ">Current Balance :</div>
                  <div className="bg-[#2D2D2D] text-white px-3 py-[2px] rounded-lg">
                    {currentBalance}
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-2">
                  <div className="font-semibold ">Invested Amount :</div>
                  <div className="bg-[#2D2D2D] text-white px-3 py-[2px] rounded-lg">
                    {investedAmount}
                  </div>
                </div>
              </div>
            </div>

            {/* <div
                className="flex justify-center mt-8 w-full"
              >
                <button className="flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1">
                  <RiEditLine size={22} />
                  <div>Edit Profile</div>
                </button>
              </div> */}
          </div>

          <div className="mt-8">
            <div className="text-[42px] text-[#C5C5C5] font-semibold mt-4">
              Broker Account Configuration
            </div>
            <div className="flex bg-[#202020] border border-[#353535] text-[#9b9b9b] drop-shadow-2xl rounded-3xl mt-8 py- 10 px- 4 h-[400px] w-full overflow-hidden">
              <div className="drop-shadow-2xl bg-[#151515] w-fit">
                <div className="w-fit h-full scroll-y-auto scrollbar border- r-2 border-[#424242] px-4 py-6">
                  {/* <img src={tradier} onClick={() => setSelectedService("tradier")} className={`size-10 p-1 rounded-lg cursor-pointer ${selectedService === "tradier" ? "border-2 border-tertiary [#424242]" : ""}`} /> */}
                  {/* <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]"/> */}
                  <img
                    src={eTrade}
                    onClick={() => setSelectedService("eTrade")}
                    className={`size-10 p-1 mt-2 rounded-lg cursor-pointer ${
                      selectedService === "eTrade"
                        ? "border-2 border-tertiary [#424242]"
                        : ""
                    }`}
                  />
                  <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]" />
                  <img
                    src={weBull}
                    onClick={() => {
                      setSelectedService("weBull");
                    }}
                    className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${
                      selectedService === "weBull"
                        ? "border-2 border-tertiary [#424242]"
                        : ""
                    }`}
                  />
                  <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]" />
                  <img
                    src={polygon}
                    onClick={() => {
                      setSelectedService("polygon");
                    }}
                    className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${
                      selectedService === "polygon"
                        ? "border-2 border-tertiary [#424242]"
                        : ""
                    }`}
                  />
                </div>
              </div>

              <div className="relative px-6 mt-6 w-full">
                {(() => {
                  switch (selectedService) {
                    case "eTrade":
                      return (
                        <>
                          <div className="text-4xl font-semibold">ETRADE</div>
                          <div className="w-full h-[2px] bg-[#353535] mt-2" />
                          <div className="mt-8">
                            <div className="flex gap-2 justify-between items-center">
                              <div className="w-1/2 ">
                                <div className="text-sm font-semibold">
                                  Consumer Key
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={key} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-sm font-semibold">
                                  Consumer Secret
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={secret} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => setConfigDialogEtrade(true)}
                            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1"
                          >
                            <RiEditLine size={22} />
                            <div>Edit Config</div>
                          </button>
                        </>
                      );
                    case "weBull":
                      return (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-4xl font-semibold">WEBULL</div>
                          </div>
                          <div className="w-full h-[2px] bg-[#353535] mt-2" />
                          <div className="mt-8">
                            <div className="grid grid-cols-2 gap-2 justify-between items-center flex-wrap">
                              <div className="w-full 1/2 ">
                                <div className="text-sm font-semibold">
                                  Email
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={key} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                              <div className="w-full 1/2">
                                <div className="text-sm font-semibold">
                                  Password
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={secret} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                              <div className="w-full 1/2">
                                <div className="text-sm font-semibold">
                                  Trading Pin
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={secret} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>

                              <div className="w-full 1/2">
                                <div className="text-sm font-semibold">
                                  Security DID
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={secret} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => setConfigDialogWeBull(true)}
                            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1"
                          >
                            <RiEditLine size={22} />
                            <div>Edit Config</div>
                          </button>
                        </>
                      );
                    case "polygon":
                      return (
                        <>
                          <div className="text-4xl font-semibold">POLYGON</div>
                          <div className="w-full h-[2px] bg-[#353535] mt-2" />
                          <div className="mt-8">
                            <div className="grid grid-cols-2 gap-2 justify-between items-center flex-wrap">
                              <div className="w-full 1/2 ">
                                <div className="text-sm font-semibold">
                                  API Key
                                </div>
                                <div className="flex items-center mt-2 gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                                  <img src={key} className="size-5" />
                                  <div className="bg-transparent outline-none placeholder:text-[#9b9b9b]">
                                    *******************
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => setConfigDialogPolygon(true)}
                            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1"
                          >
                            <RiEditLine size={22} />
                            <div>Edit Config</div>
                          </button>
                        </>
                      );
                    default:
                      return <div className="text-center">No Service Selected</div>;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-[30%] -translate-y-[60%]">
          <Loader />
        </div>
      )} */}
    </div>
  );
};

export default Profile;
