import React, { useState } from "react";

const ModelTable = () => {
    const [rowData, setRowData] = useState([
        {ticker: "AAPL",
            
        }
    ])
  return (
    <div className="mt-12">
        <div className="flex text-[#7D7D7D] border-b border-b-[#7D7D7D] text-center text-sm font-bold">
            <div className="w-[8%] pb-2 pl-2">Ticker</div>
            <div className="w-[8%] pb-2">Curr. Price</div>
            <div className="w-[11%] pb-2">Exp. Date</div>
            <div className="w-[6%] pb-2">Call | Put</div>
            <div className="w-[10%] pb-2">Near</div>
            <div className="flex items-center justify-evenly gap-2 border-l border-l-[#7D7D7D] text-tertiary pb-2 px-2 w-[16%]">
                <div className="w-full">Strike</div>
                <div className="w-full">Quote</div>
                <div className="w-full">Qty</div>
            </div>
            <div className="flex items-center justify-evenly gap-2 text-tertiary pb-2 w-[16%]">
                <div className="w-full">Strike</div>
                <div className="w-full">Quote</div>
                <div className="w-full">Qty</div>
            </div>
            <div className="flex items-center justify-evenly gap-2 text-tertiary pb-2 w-[16%]">
                <div className="w-full">Strike</div>
                <div className="w-full">Quote</div>
                <div className="w-full">Qty</div>
            </div>
            <div className="flex items-center justify-evenly gap-2 text-tertiary pb-2 pr-2 w-[16%]">
                <div className="w-full">Strike</div>
                <div className="w-full">Quote</div>
                <div className="w-full">Qty</div>
            </div>
            <div className="flex items-center gap-1 text-tertiary pb-2 pr-2 w-[7%]">
                <div className="w-full">Qty</div>
            </div>
            <div className="flex items-center gap-1 text-tertiary pb-2 pr-2 w-[4%]">
            </div>
        </div>

    </div>
  );
};

export default ModelTable;
