import axios from "axios";
import { userStore } from "../store/user";

export const getUserBtoData = (token) =>
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/bto/getBtoData`, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      allTrades: true,
      // paperTrade: true,
      brokerId: process.env.REACT_APP_TRADIER_SERVICE_ID
    }
  });

export const getUserBtoModelData = (token, isPaperTrade, authBrokerId) => 
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/bto/getBtoData`, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // allTrades: true,
      paperTrade: isPaperTrade,
      brokerId: isPaperTrade ? undefined : authBrokerId
    }
  });

export const sellFullBto = (token, id, data) =>
  axios.put(`${process.env.REACT_APP_API_BASE_URL}/bto/${id}`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

export const getProfitAndLoss = (token, value) =>
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/pnl/compute`, value, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
