import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import routes from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userStore } from "./store/user";
import { useEffect, useState } from "react";
import { getUser } from "./API/user";
import { getUserBtoData, getUserBtoModelData } from "./API/bto";
import { io } from "socket.io-client";
import axios from "axios";
import tickers from "./pages/User/popularTickers";
import moment from "moment";
import { getExpirationDatesOfTicker, getExpirationDatesOfTickerTest } from "./helper";
import Error from "./pages/Error/index";
import "react-loading-skeleton/dist/skeleton.css";

import CryptoJS from "crypto-js";
import { PiFileMagnifyingGlassDuotone } from "react-icons/pi";
import useModelStore from "./store/modelStore";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramAuth, setParamAuth] = useState();
  const {
    userData,
    loading,
    setUserData,
    setBtoLoading,
    setBtoData,
    btoModelData,
    setBtoModelData,
    socket,
    setSocket,
    wishlistData,
    setWishListData,
    setExpirationDatesOfTicker,
    uniqueString,
    brokerList,
    setBrokerList,
    hasDataBrokerTokens,
    setHasDataBrokerTokens,
    hasBrokerTokens,
    setHasBrokerTokens,
    authBroker,
    authBrokerId,
    setAuthBroker,
    setAuthBrokerId,
    dataAuthBroker,
    setDataAuthBroker,
    setDataAuthBrokerId,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    brokerBalance,
    setBrokerBalance,
    isPaperTrade,
    shiftBto,
    balanceLoader,
    setBalanceLoader,
    setIsPaperTrade,
    modelData,
    setModelData
  } = userStore((state) => state);
  
  const {
    selectedOptionArray,
    setSelectedOptionArray,
  } = useModelStore((state) => state);

  const { id: userId, currentBalance } = userData || {};
  const navigate = useNavigate();
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem("baseURL");

  console.log("id", brokerList)

  const tokensAvailable = async () => {
    try {
      
      const brokerListResp = await fetchBrokers();
      console.log("starting this step")
      await axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/oauth/test?userId=${localStorage.getItem("userId")}`
        )
        .then(async (res) => {
          if (res.status === 200) {
  
            if (res.data?.outhStatus?.data?.status) {
              switch (res.data?.outhStatus?.data?.broker) {
                case "Tradier":
                  setDataAuthBroker("Tradier");
                  setDataAuthBrokerId(brokerListResp["Tradier"]);
                  break;
                case "Etrade":
                  setDataAuthBroker("Etrade");
                  setDataAuthBrokerId(brokerListResp["Etrade"]);
                  break;
                case "Polygon":
                  setDataAuthBroker("Polygon");
                  setDataAuthBrokerId(brokerListResp["Polygon"]);
                  break;
                default:
                  setDataAuthBroker("Webull");
                  setDataAuthBrokerId(brokerListResp["Webull"]);
                  break;
              }
            
              setHasDataBrokerTokens(true);
            } else {
              setHasDataBrokerTokens(false);
            }
  
            if(res.data?.outhStatus?.wallet?.status){ 
  
              switch (res.data?.outhStatus?.wallet?.broker) {
                case "Tradier":
                  setAuthBroker("Tradier"); 
                  setAuthBrokerId(brokerListResp["Tradier"]);
                  setAuthBrokerId("Tradier");
                  break;
                case "Etrade":
                  setAuthBroker("Etrade"); 
                  setAuthBrokerId(brokerListResp["Etrade"]); 
                  break;
                default:
                  setAuthBroker("Webull"); 
                  setAuthBrokerId(brokerListResp["Webull"]); 
                  console.log("ID where it's needed", brokerListResp["Webull"])
                  break;
              }
            
  
              if(localStorage.getItem("paperTrade") == "false"){
                setIsPaperTrade(false)
              }
    
              setHasBrokerTokens(true);          
  
              await axios
              .get(`${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` + localStorage.getItem('userId'), {
                params: {
                  brokerId: brokerListResp[res.data?.outhStatus?.wallet?.broker]
                }
              })
                .then((res) => {
                  console.log(res.data.accountList);
                  setAccountList(res.data.accountList);
                  setSelectedAccount(res.data.accountList[0]?.accountIdKey);
                })
                .catch((err) => console.error(err));
            } else {
              setHasBrokerTokens(false);
              localStorage.setItem("paperTrade", true)
              setIsPaperTrade(true)
            }
          }
        })
    } catch (error) {
      // if (res.response.status === 404) setHasBrokerTokens(false);
        // else console.log(res.response.statusText);
        if (error.response.status !== 404) console.log(error.response.statusText);

        setHasBrokerTokens(false)
        setHasDataBrokerTokens(false)
    }
  };

  console.log("ID", authBrokerId)

  const fetchBrokers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/broker/all`
      );
      const brokerListData = response?.data?.brokerList;
  
      const brokerIds = brokerListData.reduce((acc, broker) => {
        acc[broker.name] = broker.id;
        return acc;
      }, {});
  
      setBrokerList(brokerIds);
      return brokerIds;
    } catch (error) {
      console.log(error);
      return null
    }
  };
  
  useEffect(() => {
    const authToken = searchParams.get("authToken");
    const serverId = searchParams.get("serverId");
    const subDomainUrl = searchParams.get("baseURL");
    console.log(decodeURIComponent(subDomainUrl));
    if (authToken) {

      const decodedToken = decodeURIComponent(authToken);
      const decryptedToken = CryptoJS.AES.decrypt(
        decodedToken,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      const decryptedServerId = CryptoJS.AES.decrypt(
        serverId,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      console.log("Decrypted Token:", decryptedToken); // Debugging
      localStorage.setItem("gentradeToken", decryptedToken);
      localStorage.setItem("baseURL", decodeURIComponent(subDomainUrl));
      localStorage.setItem("serverId", decodeURIComponent(decryptedServerId));

      
    }
    // tokensAvailable();

    if(socket){
      socket.on('destroy_socket_client', () => {
        setHasDataBrokerTokens(false);
        socket.disconnect();
        setSocket(null);
      })
    }
  }, []);

  useEffect(() => {

    if (gentradeToken && !userId) {
      (async () => {
        try {
          const response = await getUser(gentradeToken);
          setUserData(response?.data);
          console.log(response?.data);
          localStorage.removeItem("btoMetaData");
          localStorage.removeItem("cachedDiscordChannels");
        } catch (err) {
          console.log(err);
          toast(
            err?.response?.data ||
              "Something went wrong, please try again later.",
            { type: "error" }
          );
          localStorage.clear();
          // navigate(`${baseURL}/signIn`);
          if (subDomainUrl && gentradeToken) {
            localStorage.clear();
            setUserData(null);
            window.location.href = `${subDomainUrl}/signIn`;
          } else {
            localStorage.clear();
            navigate("/signIn");
            setUserData(null);
          }
        }
      })();
    }

    if (gentradeToken && userId) {
      tokensAvailable();
      //fetching btosData for the user
      (async () => {
        try {
          setBtoLoading(true);
          const btoResponse = await getUserBtoData(gentradeToken);
          const { activeBtos, expiredBtos, soldBtos } = btoResponse.data || {};
          let expiredValues = [];
          if (expiredBtos?.length) {
            expiredValues = expiredBtos.map((d) => ({
              ...d,
              pnlText: "BTO has expired",
            }));
          }

          setBtoData({
            expired: expiredValues,
            active: activeBtos,
            sold: soldBtos?.length
              ? soldBtos.map((d) => ({
                  ...d,
                  pnlText: "BTO has been sold out",
                }))
              : [],
          });
          
          const btoModelResponse = await getUserBtoModelData(gentradeToken, isPaperTrade, authBrokerId)
          const { activeBtos:activeModelBtos , processingBtos:processingModelBtos, expiredBtos:expiredModelBtos , soldBtos:soldModelBtos } = btoModelResponse.data || {};

          let expiredModelValues = [];
          if (expiredModelBtos?.length) {
            expiredModelValues = expiredModelBtos.map((d) => ({
              ...d,
              pnlText: "BTO has expired",
            }));
          }

          setBtoModelData({
            expired: expiredModelValues,
            active: activeModelBtos,
            processing: processingModelBtos,
            sold: soldModelBtos?.length
              ? soldModelBtos.map((d) => ({
                  ...d,
                  pnlText: "BTO has been sold out",
                }))
              : [],
          });

          setBtoLoading(false);
        } catch (err) {
          console.log(err);
          toast(
            err?.response?.data ||
              "Something went wrong, please try again later",
            { type: "error" }
          );
          setBtoLoading(false);
        }
      })();
    }

    return () => {
      socket?.disconnect();
    };
  }, [userId, gentradeToken]);


  useEffect(() => {
    if (!socket && gentradeToken && userData && hasDataBrokerTokens && (hasBrokerTokens !== null)) {
      const registerData = {
        balance: currentBalance < 1000 ? currentBalance : 1000,
      };
      const registerDataTest = {
        balance: currentBalance < 1000 ? currentBalance : 1000,
      };
      const newSocket = io(process.env.REACT_APP_SOCKET_API_BASE_URL, {
        auth: { token: process.env.REACT_APP_SOCKET_API_TOKEN },
        transports: ["websocket"],
      });

      setSocket(newSocket);
      (async () => {
        try {
          const expirationDates = await getExpirationDatesOfTicker();
          setExpirationDatesOfTicker(expirationDates);
          // console.log(expirationDates);

          console.log("just checking", selectedOptionArray)
          
          await Promise.all(
            selectedOptionArray.map(async (ticker) => {
              console.log("Processing ticker:", ticker);
  
              let current;
              let expirationDate =
                expirationDates[ticker]?.[0] || moment().add("2", "days").format("YYYY-MM-DD");
  
              try {
                const response = await axios({
                  method: "get",
                  url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
                });
  
                if (response.status === 200 && response?.data?.c) {
                  current = response?.data?.c?.toString();
                }
              } catch (error) {
                toast(error.message, { type: "error" });
                current = "1000";
              }
  
              registerData[ticker] = [current, `${expirationDate}`, "call"];
            })
          );
          
          // for (let i = 0; i < 5; i++) {
          //   const ticker = tickers[i];
          //   let current;
          //   let expirationDate =
          //     expirationDates[ticker]?.[0] ||
          //     moment().add("2", "days").format("YYYY-MM-DD");
          //   try {
          //     const response = await axios({
          //       method: "get",
          //       url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
          //     });
          //     if (response.status === 200 && response?.data?.c) {
          //       current = response?.data?.c?.toString();
          //     }
          //   } catch (error) {
          //     toast(error, {
          //       type: "error",
          //     });

          //     current = "1000";
          //   }
          //   registerData[ticker] = [current, `${expirationDate}`, "call"];
          // }

          //New Logic
          
          // let initialData = modelData
          // if(modelData.length === 0){
          //   //add default ticker for model Data
          //   const defaultTickerObject = {

          //   }
          // }
          //   const expirationDatesTest = await getExpirationDatesOfTickerTest(modelData)
            
          //   modelData?.forEach(async (ticker) => {
          //     const ticker = ticker?.name;
          //     let current;
          //     let expirationDate =
          //       expirationDatesTest[ticker]?.[0] ||
          //       moment().add("2", "days").format("YYYY-MM-DD");

          //     try {
          //       const response = await axios({
          //         method: "get",
          //         url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
          //       });
          //       if (response.status === 200 && response?.data?.c) {
          //         current = response?.data?.c?.toString();
          //       }
          //     } catch (error) {
          //       toast(error, {
          //         type: "error",
          //       });
  
          //       current = "1000";
          //     }
              
          //     registerDataTest[ticker] = [current, `${expirationDate}`, "call"];
          //     // add these values to model Data
              
          //   });



          newSocket.emit("register", {
            registerData,
            // userId: localStorage.getItem("userId"),
            isRealTrade: !isPaperTrade,
            userId: userData.id,
          });
          setWishListData(registerData);
        } catch (err) {
          console.log(err);
          toast("Something went wrong, please try again later", {
            type: "er",
          });
        }
      })();

      setInterval(() => {
        const state = userStore.getState();
        newSocket.emit("ping", {
          registerData: state.wishlistData,
          isRealTrade: !(state.isPaperTrade),
          userId: userId || localStorage.getItem("userId"),
          changeId: state.uniqueString,
        });
      }, 15000);
    } else if (socket && !hasDataBrokerTokens) {
      socket.disconnect();
      setSocket(null);
    }
  }, [socket, userId, userData, hasDataBrokerTokens, hasBrokerTokens, selectedOptionArray]);

  useEffect(() => {
      if (!socket) return;
  
      socket.on('destroy_socket_client', () => {
        setHasDataBrokerTokens(false);
        socket.disconnect();
        setSocket(null);
      })

      
    }, [socket]);

  useEffect(() => {
    if (hasBrokerTokens && selectedAccount) {
      const updateBalance = async () => {
        setBalanceLoader(true)
        await axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/broker/account_balance/` +
              localStorage.getItem("userId") + "/" + selectedAccount , {
                params: {
                  brokerId: authBrokerId
                }
              }
          )
          .then((res) => {
            console.log(res.data);
            setBrokerBalance(res.data);
          })
          .catch((err) => console.error(err))
          .finally(() => setBalanceLoader(false))
      };
      updateBalance();
    }
  }, [selectedAccount, authBrokerId]);

  // console.log(uniqueString)

  return (
    <div className="bg-[#111111] min-h-screen">
      {/* <Header /> */}

      <ToastContainer />
      <Routes>
        {routes.map((item, index) => (
          <Route
            key={"" + index}
            path={item.path}
            exact={item.exact}
            name={item.name}
            element={item.element}
            errorElement={<Error />}
          />
        ))}
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>

      {/* <Footer />  */}
    </div>
  );
}

export default App;
